class SentryDriver {
  constructor() {
  }

  init() {
    setTimeout(() => {
      if (window.Sentry) {
        if (window.Sentry && window.Sentry.setUser && typeof window.Sentry.setUser === 'function') {
          window.Sentry.setUser(this.#getUser());
        }
      }
    }, 5000);
  }

  #getUser() {
    if (window.Telegram && window.Telegram.WebApp) {
      const initData = window.Telegram.WebApp.initDataUnsafe;
      if (initData && initData.user && window.Sentry) {
        return {
          id: initData.user.id,
          username: initData.user.username,
          firstName: initData.user.first_name,
          lastName: initData.user.last_name
        }
      }
    }

    return {};
  }
}

export {SentryDriver};