import axios from "axios";

export const ClientMessageTypes = {
  Echo: 0,
  ManualSpin: 1,
  StartSpin: 2,
  StopSpin: 3,
  Sync: 4,
  SyncFriends: 5,
  FriendEmoji: 6,
}

export class BackendDriver {
  /** @type {WebSocket} */
  #ws = null;
  #webSocketUrl = '';
  #webSocketProtocol = '';
  #baseUrl = '';
  #initData = '';
  #subscribedForFriendUpdates = false;

  getBaseUrl() {
    return this.#baseUrl;
  }

  async init() {
    this.initEnvironment();
    await this.initWebsocket();
    window.addEventListener('beforeunload', this.closeWebSocket.bind(this));
  }

  initEnvironment() {
    this.#baseUrl = "https://spinner.minercaravan.com";

    if (window.Telegram && window.Telegram.WebApp) {
      const initData = window.Telegram.WebApp.initData;
      this.#initData = btoa(initData);
    }

    // todo: Do not push to production.
    // this.#baseUrl = 'http://192.168.101.170:8080';
    // const initDataTest = btoa('user=%7B%22id%22%3A5077488730%2C%22first_name%22%3A%22Heinhain%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22He1nhain%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%2C%22photo_url%22%3A%22https%3A%5C%2F%5C%2Ft.me%5C%2Fi%5C%2Fuserpic%5C%2F320%5C%2F_j9A8tmlStQnKsHimUH3xogZrxANDetBEbZwNMJSxLSZMRISCySAEpC1bLXpTJrr.svg%22%7D&chat_instance=-3250196733706180568&chat_type=sender&auth_date=1735198348&signature=6i-cIN_X5MM9HvrnKTJq4cKQ0VlTGYQRaea0sNq7k19MLaOBDn6ggCVGURszTGZzjUp9FrDkt0JdZRF6lyUfDA&hash=480c68d8440ee8f8c50e12acadc08cb23c3379c8a18217fb0009f4b109ba1e66');
    // this.#initData = btoa(initDataTest);
    // todo: Do not push to production.

    // noinspection HttpUrlsUsage
    this.#webSocketUrl = this.#baseUrl.replace('http://', '').replace('https://', '');
    this.#webSocketProtocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
  }

  async getPlayerData() {
    const defaultData = {
      status: "OK",
      name: "",
      spins: 0,
      battery: 0,
      energy: 0,
      settings: {
        baseBatteryCapacity: 0,
        dailyLubricantBoostValue: 0,
        dailyMultiplierBoostDuration: 0,
        multiplierUpgradeMaxLevel: 0,
        rechargeUpgradeMaxLevel: 0,
        batteryUpgradeMaxLevel: 0,
        lubricantUpgradeMaxLevel: 0,
        velocityUpgradeMaxLevel: 0,
        velocityMin: 0,
        velocityMax: 0,
        baseMultiplier: 0,
        minVelocityDecay: 0,
        maxVelocityDecay: 0,
        refRewardBase: 0,
        refRewardPremium: 0,
      },
      upgrades: {
        multiplier: {
          id: 1,
          title: "",
          description: "",
          icon: "",
          level: 0,
          cost: 0,
        },
        recharge: {
          id: 2,
          title: "",
          description: "",
          icon: "",
          level: 0,
          cost: 0,
        },
        battery: {
          id: 3,
          title: "",
          description: "",
          icon: "",
          level: 0,
          cost: 0,
        },
        lubricant: {
          id: 4,
          title: "",
          description: "",
          icon: "",
          level: 0,
          cost: 0,
        },
        velocity: {
          id: 5,
          title: "",
          description: "",
          icon: "",
          level: 0,
          cost: 0,
        },
      },
      dailyBoosts: {
        multiplier: {
          id: 1,
          title: "",
          description: "",
          icon: "",
          remaining: 0,
          max: 0,
        },
        recharge: {
          id: 2,
          title: "",
          description: "",
          icon: "",
          remaining: 0,
          max: 0,
        },
        lubricant: {
          id: 3,
          title: "",
          description: "",
          icon: "",
          remaining: 0,
          max: 0,
        },
      },
    };

    try {
      const res = await axios.get(this.#baseUrl.concat('/api/player'), {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.#initData,
        },
      });

      if (res.status === 200) {
        console.log(res.data);
        return res.data;
      }

      this.showErrorPopup('Failed to get player state');
      return defaultData;
    } catch (e) {
      console.error(e);
      this.showErrorPopup('Failed to get player state');
      return defaultData;
    }
  }

  async getDailyBoosts() {
    const defaultData = {
      status: "OK",
      dailyBoosts: {
        multiplier: {
          id: 1,
          title: "",
          description: "",
          icon: "",
          remaining: 0,
          max: 0,
        },
        recharge: {
          id: 2,
          title: "",
          description: "",
          icon: "",
          remaining: 0,
          max: 0,
        },
        lubricant: {
          id: 3,
          title: "",
          description: "",
          icon: "",
          remaining: 0,
          max: 0,
        },
      },
    };

    try {
      const res = await axios.get(this.#baseUrl.concat('/api/daily-boost'), {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.#initData,
        },
      });

      if (res.status === 200) {
        return res.data.dailyBoosts;
      }

      this.showErrorPopup('Failed to get player state');
      return defaultData.dailyBoosts;
    } catch (e) {
      console.error(e);
      this.showErrorPopup('Failed to get player state');
      return defaultData.dailyBoosts;
    }
  }

  async getUpgrades() {
    const defaultData = {
      status: "OK",
      upgrades: {
        multiplier: {
          id: 1,
          title: "",
          description: "",
          icon: "",
          level: 0,
          cost: 0,
        },
        recharge: {
          id: 2,
          title: "",
          description: "",
          icon: "",
          level: 0,
          cost: 0,
        },
        battery: {
          id: 3,
          title: "",
          description: "",
          icon: "",
          level: 0,
          cost: 0,
        },
        lubricant: {
          id: 4,
          title: "",
          description: "",
          icon: "",
          level: 0,
          cost: 0,
        },
        velocity: {
          id: 5,
          title: "",
          description: "",
          icon: "",
          level: 0,
          cost: 0,
        },
      },
    };

    try {
      const res = await axios.get(this.#baseUrl.concat('/api/upgrade'), {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.#initData,
        },
      });

      if (res.status === 200) {
        return res.data.upgrades;
      }

      this.showErrorPopup('Failed to get player state');
      return defaultData.upgrades;
    } catch (e) {
      console.error(e);
      this.showErrorPopup('Failed to get player state');
      return defaultData.upgrades;
    }
  }

  async initWebsocket() {
    try {
      this.#webSocketProtocol.concat(this.#webSocketUrl.concat('/ws?initData=').concat(this.#initData));
      this.#ws = new WebSocket(this.#webSocketProtocol.concat(this.#webSocketUrl.concat('/ws?initData=').concat(this.#initData)));

      this.#ws.onopen = async () => {
        this.showSuccessPopup('Connected', 1000);
        if (this.webSocketConnectedCallback) {
          this.webSocketConnectedCallback();
        }
      };

      this.#ws.onmessage = (message) => {
        // console.log('Received message:', message.data);
        this.receiveDataFromWS(message);
      };

      this.#ws.onerror = (error) => {
        this.#ws.close();

        this.showErrorPopup('Connection error');
        console.error(error);
      }

      this.#ws.onclose = (event) => {
        if (!event.wasClean || event.code !== 1005) {
          this.showErrorPopup('Connection to server lost');
          console.error(event);
          setTimeout(this.initWebsocket.bind(this), 1000);
        }
      }
    } catch (e) {
      this.showErrorPopup('Failed to connect to server');
      console.error(e);
    }
  }

  async getLeaderboard() {
    try {
      const res = await axios.get(this.#baseUrl.concat('/api/leaderboard'), {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.#initData
        }
      });

      if (res.status === 200) {
        return res.data;
      }

      this.showErrorPopup('Failed to get leaderboard');
      return {leaderboard: []};
    } catch (e) {
      console.error(e);
      this.showErrorPopup('Failed to get leaderboard');
      return {leaderboard: []};
    }
  }

  async getFriends(offset = 0, limit = 100) {
    try {
      const res = await axios.get(this.#baseUrl.concat(`/api/friends?offset=${offset}&limit=${limit}`), {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.#initData
        }
      });
      if (res.status === 200) {
        return res.data;
      }

      this.showErrorPopup('Failed to get friends');
      return {friends: [], totalFriends: 0, totalRewards: 0, inviteCode: ""};
    } catch (e) {
      console.error(e);
      this.showErrorPopup('Failed to get friends');
      return {friends: [], totalFriends: 0, totalRewards: 0, inviteCode: ""};
    }
  }

  async getCode() {
    try {
      const res = await axios.get(this.#baseUrl.concat('/api/friends/invite-code'), {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.#initData
        }
      });

      if (res.status === 200 && res.data.inviteCode) {
        localStorage.setItem('referralCode', res.data.inviteCode);
        return res.data.inviteCode;
      }

      this.showErrorPopup('Failed to get referral code');
      return '';
    } catch (e) {
      console.error(e);
      this.showErrorPopup('Failed to get referral code');
      return '';
    }
  }

  async buyUpgrade(upgradeType) {
    switch (upgradeType) {
      case 'multiplier':
        return this.upgradeMultiplier();
      case 'recharge':
        return this.upgradeRecharge();
      case 'battery':
        return this.upgradeBattery();
      case 'lubricant':
        return this.upgradeLubricant();
      case 'velocity':
        return this.upgradeVelocity();
    }
  }

  async activateDailyBoost(boostType) {
    switch (boostType) {
      case 'multiplier':
        return this.activateDailyMultiplierBoost();
      case 'recharge':
        return this.activateDailyRechargeBoost();
      case 'lubricant':
        return this.activateDailyLubricantBoost();
      default:
        return {remaining: 0};
    }
  }

  async activateDailyMultiplierBoost() {
    try {
      const res = await axios.post(this.#baseUrl.concat('/api/daily-boost/multiplier'), null, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.#initData
        }
      });

      if (res.status === 200) {
        this.showSuccessPopup('Daily multiplier activated', 1000);
        return {
          multiplier: parseInt(res.data['multiplier'], 10),
          remaining: parseInt(res.data['remaining'], 10),
          activatedAt: new Date(res.data['activatedAt'])
        };
      }

      this.showErrorPopup('Failed to activate daily multiplier');
      return {
        multiplier: 0,
        remaining: 0,
        activatedAt: new Date()
      };
    } catch (e) {
      console.error(e);
      this.showErrorPopup('Failed to activate daily multiplier');
      return {
        multiplier: 0,
        remaining: 0,
        activatedAt: new Date()
      };
    }
  }

  async activateDailyRechargeBoost() {
    try {
      const res = await axios.post(this.#baseUrl.concat('/api/daily-boost/recharge'), null, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.#initData
        }
      });

      if (res.status === 200) {
        this.showSuccessPopup('Daily recharge activated', 1000);
        return {
          remaining: parseInt(res.data['remaining'], 10)
        };
      }

      this.showErrorPopup('Failed to activate daily recharge');
      return {
        remaining: 0
      };
    } catch (e) {
      console.error(e);
      this.showErrorPopup('Failed to activate daily recharge');
      return {
        remaining: 0
      };
    }
  }

  async activateDailyLubricantBoost() {
    try {
      const res = await axios.post(this.#baseUrl.concat('/api/daily-boost/lubricant'), null, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.#initData
        }
      });

      if (res.status === 200) {
        this.showSuccessPopup('Daily lubricant activated', 1000);
        return {
          remaining: parseInt(res.data['remaining'], 10)
        };
      }

      this.showErrorPopup('Failed to activate daily lubricant');
      return {
        remaining: 0
      };
    } catch (e) {
      console.error(e);
      this.showErrorPopup('Failed to activate daily lubricant');
      return {
        remaining: 0
      };
    }
  }

  async upgradeMultiplier() {
    try {
      const res = await axios.post(this.#baseUrl.concat('/api/upgrade/multiplier'), null, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.#initData
        },
      });

      if (res.status === 200) {
        this.showSuccessPopup('Multiplier upgraded', 1000);
        return {
          level: parseInt(res.data['level'], 10),
          maxLevel: parseInt(res.data['maxLevel'], 10),
          spent: parseInt(res.data['spent'], 10),
          cost: parseInt(res.data['cost'], 10),
          spins: parseInt(res.data['spins'], 10)
        };
      }

      return {
        error: "Failed to upgrade multiplier",
      };
    } catch (e) {
      if (e.response && e.response.data && e.response.data.error) {
        console.error(e.response.data.error);
        return {
          error: e.response.data.error,
        };
      } else {
        return {
          error: "Failed to upgrade multiplier",
        }
      }
    }
  }

  async upgradeRecharge() {
    try {
      const res = await axios.post(this.#baseUrl.concat('/api/upgrade/recharge'), null, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.#initData
        },
      });

      if (res.status === 200) {
        this.showSuccessPopup('Recharge upgraded', 1000);
        return {
          level: parseInt(res.data['level'], 10),
          maxLevel: parseInt(res.data['maxLevel'], 10),
          spent: parseInt(res.data['spent'], 10),
          cost: parseInt(res.data['cost'], 10),
          spins: parseInt(res.data['spins'], 10)
        };
      }

      return {
        error: "Failed to upgrade recharge",
      };
    } catch (e) {
      if (e.response && e.response.data && e.response.data.error) {
        console.error(e.response.data.error);
        return {
          error: e.response.data.error,
        };
      } else {
        return {
          error: "Failed to upgrade recharge",
        }
      }
    }
  }

  async upgradeBattery() {
    try {
      const res = await axios.post(this.#baseUrl.concat('/api/upgrade/battery'), null, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.#initData
        },
      });

      if (res.status === 200) {
        this.showSuccessPopup('Battery upgraded', 1000);
        return {
          level: parseInt(res.data['level'], 10),
          maxLevel: parseInt(res.data['maxLevel'], 10),
          spent: parseInt(res.data['spent'], 10),
          cost: parseInt(res.data['cost'], 10),
          spins: parseInt(res.data['spins'], 10)
        };
      }

      return {
        error: "Failed to upgrade battery",
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.error) {
        console.error(e.response.data.error);
        return {
          error: e.response.data.error,
        };
      } else {
        return {
          error: "Failed to upgrade battery",
        }
      }
    }
  }

  async upgradeLubricant() {
    try {
      const res = await axios.post(this.#baseUrl.concat('/api/upgrade/lubricant'), null, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.#initData
        },
      });

      if (res.status === 200) {
        this.showSuccessPopup('Lubricant upgraded', 1000);
        return {
          level: parseInt(res.data['level'], 10),
          maxLevel: parseInt(res.data['maxLevel'], 10),
          spent: parseInt(res.data['spent'], 10),
          cost: parseInt(res.data['cost'], 10),
          spins: parseInt(res.data['spins'], 10)
        };
      }

      return {
        error: "Failed to upgrade lubricant",
      };
    } catch (e) {
      if (e.response && e.response.data && e.response.data.error) {
        console.error(e.response.data.error);
        return {
          error: e.response.data.error,
        };
      } else {
        return {
          error: "Failed to upgrade lubricant",
        }
      }
    }
  }

  async upgradeVelocity() {
    try {
      const res = await axios.post(this.#baseUrl.concat('/api/upgrade/velocity'), null, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.#initData
        },
      });

      if (res.status === 200) {
        this.showSuccessPopup('Velocity upgraded', 1000);
        return {
          level: parseInt(res.data['level'], 10),
          maxLevel: parseInt(res.data['maxLevel'], 10),
          spent: parseInt(res.data['spent'], 10),
          cost: parseInt(res.data['cost'], 10),
          spins: parseInt(res.data['spins'], 10)
        };
      }

      return {
        error: "Failed to upgrade velocity",
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.error) {
        console.error(e.response.data.error);
        return {
          error: e.response.data.error,
        };
      } else {
        return {
          error: "Failed to upgrade velocity",
        }
      }
    }
  }

  async subscribeForFriendUpdates() {
    if (this.#subscribedForFriendUpdates) {
      return true;
    }

    try {
      const res = await axios.post(this.#baseUrl.concat('/api/friends/online/subscribe'), null, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.#initData
        },
      });

      if (res.status === 200) {
        this.#subscribedForFriendUpdates = true;
        return true;
      }

      return false;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async unsubscribeFromFriendUpdates() {
    try {
      const res = await axios.post(this.#baseUrl.concat('/api/friends/online/unsubscribe'), null, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.#initData
        },
      });

      if (res.status === 200) {
        this.#subscribedForFriendUpdates = false;
        return true;
      }

      return false;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  /** @param {string} data */
  async sendDataToWS(data) {
    try {
      if (this.#ws && this.#ws.readyState === WebSocket.OPEN) {
        this.#ws.send(data);
      }
    } catch (e) {
      this.showErrorPopup('Failed to send data to server');
      console.error(e);
    }
  }

  /** @param {MessageEvent} message */
  receiveDataFromWS(message) {
    if (this.webSocketMessageCallback) {
      this.webSocketMessageCallback(message);
    }
  }

  /** @param {string} message
   * @param {number} timeout */
  showSuccessPopup(message, timeout = 1) {
    if (this.showMessageCallback) {
      this.showMessageCallback('success', message, timeout);
    }
  }

  /** @param {string} message
   * @param {number} timeout */
  showErrorPopup(message, timeout = 3) {
    if (this.showMessageCallback) {
      this.showMessageCallback('error', message, timeout);
    }
  }

  /** @param {function} callback */
  setPopupMessageCallback(callback) {
    this.showMessageCallback = callback;
  }

  /** @param {function} callback */
  setWebSocketMessageCallback(callback) {
    this.webSocketMessageCallback = callback;
  }

  /** @param {function} callback */
  setWebSocketConnectedCallback(callback) {
    this.webSocketConnectedCallback = callback;
  }

  closeWebSocket() {
    if (this.#ws) {
      this.#ws.close();
    }
  }
}
