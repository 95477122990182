import {postEvent} from "@tma.js/sdk";

/**
 * The HapticsDriver class is responsible for managing the haptic feedback functionality
 * on supported platforms using the Telegram WebApp API.
 */
export class HapticsDriver {
  init() {
    const isEnabledValue = window.localStorage.getItem('hapticsDriver.isEnabled');
    if (isEnabledValue) {
      this.#isEnabled = JSON.parse(isEnabledValue);
    }
  }

  playNotificationSuccessHaptic() {
    if (!window.hasOwnProperty('Telegram') || !window.Telegram.hasOwnProperty('WebApp') || !this.#isEnabled) {
      return;
    }

    switch (window.Telegram.WebApp.platform) {
      case 'android':
        postEvent("web_app_trigger_haptic_feedback", {type: "notification", notification_type: "success"});
        break;
      case 'ios':
        postEvent("web_app_trigger_haptic_feedback", {type: "notification", notification_type: "success"});
        break;
      default:
        break;
    }
  }

  playNotificationErrorHaptic() {
    if (!window.hasOwnProperty('Telegram') || !window.Telegram.hasOwnProperty('WebApp') || !this.#isEnabled) {
      return;
    }

    switch (window.Telegram.WebApp.platform) {
      case 'android':
        postEvent("web_app_trigger_haptic_feedback", {type: "notification", notification_type: "error"});
        break;
      case 'ios':
        postEvent("web_app_trigger_haptic_feedback", {type: "notification", notification_type: "error"});
        break;
      default:
        break;
    }
  }

  playNavigationHaptic() {
    if (!window.hasOwnProperty('Telegram') || !window.Telegram.hasOwnProperty('WebApp') || !this.#isEnabled) {
      return;
    }

    switch (window.Telegram.WebApp.platform) {
      case 'android':
        postEvent("web_app_trigger_haptic_feedback", {type: "impact", impact_style: "soft"});
        break;
      case 'ios':
        postEvent("web_app_trigger_haptic_feedback", {type: "impact", impact_style: "soft"});
        break;
      default:
        break;
    }
  }

  /**
   * Triggers haptic feedback on supported platforms (iOS and Android) through the Telegram WebApp API.
   *
   * This method checks if the Telegram WebApp API is available in the global window context,
   * and if so, sends a request to trigger light impact haptic feedback based on the platform.
   */
  playScoreHaptic() {
    if (!window.hasOwnProperty('Telegram') || !window.Telegram.hasOwnProperty('WebApp') || !this.#isEnabled) {
      return;
    }

    if (!this.#hapticDebounceTimeout) {
      switch (window.Telegram.WebApp.platform) {
        case 'android':
          postEvent('web_app_trigger_haptic_feedback', {type: 'impact', impact_style: 'light'});
          break;
        case 'ios':
          postEvent('web_app_trigger_haptic_feedback', {type: 'impact', impact_style: 'light'});
          break;
        default:
          break;
      }

      this.#hapticDebounceTimeout = setTimeout(() => {
        clearTimeout(this.#hapticDebounceTimeout);
        this.#hapticDebounceTimeout = null;
      }, this.#hapticDebounceDelay);
    }
  }

  /**
   * Toggles the haptic feedback setting between enabled and disabled states.
   */
  toggleHaptics() {
    this.#isEnabled = !this.#isEnabled;

    // Update persistent setting.
    window.localStorage.setItem('hapticsDriver.isEnabled', JSON.stringify(this.#isEnabled));
  }

  isEnabled() {
    return this.#isEnabled;
  }

  // --- Properties ---

  #isEnabled = true;

  #hapticDebounceTimeout = null;
  #hapticDebounceDelay = 333;
}
