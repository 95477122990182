/**
 * The GameLogic class is responsible for handling all the core
 * mechanics and rules of the game.
 * It manages the game's state, processes player actions, and enforces the game's rules.
 */
import {ClientMessageTypes} from "./backend-driver.js";
import {number} from "@tma.js/sdk";
import {Spinner} from "./spinner.js";

export class GameMode {
  /** @type {?AudioDriver} */
  #audioDriver = null;
  /** @type {?HapticsDriver} */
  #hapticsDriver = null;
  /** @type {?BackendDriver} */
  #backendDriver = null;
  /** @type {?FxDriver} */
  #fxDriver = null;
  /** @type {?GameUI} */
  #gameUI = null;
  /** @type {HTMLElement} */
  #spinnerEl = null;
  #spins = 0;
  #energy = 0;
  #battery = 0;
  #velocity = 0;
  #maxVelocity = 6.3; // 1000 rps
  #isTouching = false;
  /** @type {DOMHighResTimeStamp} */
  #touchStartTime = 0;
  /** @type {DOMHighResTimeStamp} */
  #lastTime = 0;
  #lastAngle = 0; // The last rotation angle of the spinner when it was not touched.
  #angle = 0; // The current rotation angle of the spinner (radians).
  #scoreAngle = 0; // Used to track the angle for score effects.
  #settings = {
    baseBatteryCapacity: 0,
    baseMultiplier: 0,
    batteryUpgradeMaxLevel: 0,
    dailyMultiplierBoostDuration: 0,
    lubricantUpgradeExponent: 0,
    lubricantUpgradeMaxLevel: 0,
    maxVelocityDecay: 0,
    minVelocityDecay: 0,
    multiplierUpgradeMaxLevel: 0,
    rechargeUpgradeMaxLevel: 0,
    refRewardBase: 0,
    refRewardPremium: 0,
    velocityMin: 0,
    velocityMax: 0,
    velocityUpgradeMaxLevel: 0,
  };
  #upgrades = {
    multiplier: {
      level: 0,
      cost: 0
    },
    battery: {
      level: 0,
      cost: 0
    },
    lubricant: {
      level: 0,
      cost: 0
    },
    recharge: {
      level: 0,
      cost: 0
    },
    velocity: {
      level: 0,
      cost: 0
    },
  };
  #dailyBoosts = {
    lubricant: 0,
    multiplier: 0,
    recharge: 0,
  };
  #dailyMultiplierBoostValue = 1;
  #dailyMultiplierBoostActivatedAt = 0;
  #touches = [];
  /** @type {Spinner} */
  #spinner = null;

  /** @param {GameUI} gameUI
   * @param {AudioDriver} audioDriver
   * @param {HapticsDriver} hapticsDriver
   * @param {BackendDriver} backendDriver
   * @param {FxDriver} fxDriver */
  async init(gameUI, audioDriver, hapticsDriver, backendDriver, fxDriver) {
    this.#gameUI = gameUI;
    this.#audioDriver = audioDriver;
    this.#hapticsDriver = hapticsDriver;
    this.#backendDriver = backendDriver;
    this.#fxDriver = fxDriver;

    this.#backendDriver.setWebSocketConnectedCallback(this.handleWebSocketConnected.bind(this));
    this.#backendDriver.setWebSocketMessageCallback(this.handleWebSocketMessage.bind(this));
    this.#spinner = new Spinner(this, this.#backendDriver, this.#audioDriver, this.#gameUI, this.#hapticsDriver);
  }

  async #initPlayerData() {
    const playerData = await this.#backendDriver.getPlayerData();
    if (!playerData) {
      return;
    }

    console.log("Player data:", playerData);

    this.#settings = playerData.settings;
    this.#upgrades = playerData.upgrades;
    this.#dailyBoosts = playerData.dailyBoosts;
    this.#energy = playerData.energy;
    this.#spins = playerData.spins;

    await this.#gameUI.setGameMode(this);
    this.#gameUI.updateScore(this.#spins);
    this.#gameUI.updateEnergy(this.#energy, this.getBatteryCapacity());
    this.#gameUI.updateReferralReward(this.#settings.refRewardBase, this.#settings.refRewardPremium);
  }

  /** @return {number} */
  getTotalSpins() {
    return this.#spins;
  }

  setTotalSpins(spins) {
    this.#spins = spins;
  }

  getEnergy() {
    return this.#energy;
  }

  getMinVelocity() {
    return this.#settings.velocityMin;
  }

  getMaxUpgradeLevel(upgradeType) {
    switch (upgradeType) {
      case "multiplier":
        return this.#settings.multiplierUpgradeMaxLevel;
      case "battery":
        return this.#settings.batteryUpgradeMaxLevel;
      case "lubricant":
        return this.#settings.lubricantUpgradeMaxLevel;
      case "recharge":
        return this.#settings.rechargeUpgradeMaxLevel;
      case "velocity":
        return this.#settings.velocityUpgradeMaxLevel;
      default:
        return 0;
    }
  }

  activateMultiplierBoost(time, multiplier) {
    this.#dailyMultiplierBoostValue = multiplier;
    this.#dailyMultiplierBoostActivatedAt = time;
  }

  /** @return {boolean} */
  #isDailyMultiplierBoostActive() {
    const now = Date.now();
    return now < this.#dailyMultiplierBoostActivatedAt + this.#settings.dailyMultiplierBoostDuration;
  }

  #isDailyLubricantBoostActive() {
    return false;
  }

  /** @return {number} */
  getMultiplier() {
    if (this.#isDailyMultiplierBoostActive()) {
      return (this.#upgrades.multiplier.level + 1) * this.#settings.baseMultiplier * this.#dailyMultiplierBoostValue;
    }
    return (this.#upgrades.multiplier.level + 1) * this.#settings.baseMultiplier;
  }

  getBatteryCapacity() {
    const batteryLevelInt = parseInt(this.#upgrades.battery.level, 10);
    if (Number.isNaN(batteryLevelInt)) {
      return this.#settings.baseBatteryCapacity;
    }
    const batteryCapacityBase = parseInt(this.#settings.baseBatteryCapacity, 10);
    return batteryLevelInt * (batteryCapacityBase / 2) + batteryCapacityBase;
  }

  getVelocityDecay() {
    if (this.#isDailyLubricantBoostActive()) {
      return this.#settings.dailyLubricantBoostValue;
    }
    return this.#settings.minVelocityDecay + (this.#settings.maxVelocityDecay - this.#settings.minVelocityDecay) * Math.pow(this.#upgrades.lubricant.level / this.#settings.lubricantUpgradeMaxLevel, this.#settings.lubricantUpgradeExponent);
  }

  /** @type {number} */
  #holdTimer = 0;
  #holdTimerDelay = 100;

  #copyTouch({identifier, pageX, pageY}) {
    return {identifier, pageX, pageY};
  }

  async sendMessageFriendEmoji(id, emoji) {
    try {
      let data = {
        t: ClientMessageTypes.FriendEmoji,
        d: {
          i: id,
          e: emoji
        }
      };

      return this.#backendDriver.sendDataToWS(JSON.stringify(data));
    } catch (error) {
      return Promise.reject(error);
    }
  }

  #playScoreEffects(score) {
    if (this.#audioDriver) {
      this.#audioDriver.playScoreSound();
    }

    if (this.#hapticsDriver) {
      this.#hapticsDriver.playScoreHaptic();
    }

    if (this.#gameUI) {
      this.#gameUI.spawnScoreParticle(score);
    }
  }

  async handleWebSocketConnected() {
    return await this.#initPlayerData();
  }

  /** @param {MessageEvent} data */
  async handleWebSocketMessage(data) {
    let jd;
    try {
      jd = JSON.parse(data.data);
    } catch (error) {
      console.error("Error parsing JSON data:", error);
      return;
    }

    let t = jd.t;
    let d = jd.d;

    switch (t) {
      case ClientMessageTypes.Sync: {
        const energy = d.e;
        const battery = d.b;
        const spins = d.s;
        const velocity = parseFloat(d.v);

        this.#energy = parseInt(energy, 10);
        this.#battery = parseInt(battery, 10);
        this.#spins = parseInt(spins, 10);

        if (!Number.isNaN(velocity)) {
          this.#velocity = velocity;
        }

        this.#gameUI.updateScore(parseInt(spins, 10));
        this.#gameUI.updateEnergy(this.#energy, this.#battery);
        break;
      }
      case ClientMessageTypes.SyncFriends: {
        const friends = d.f;
        await this.syncFriendsSpinners(friends);
        break;
      }
      case ClientMessageTypes.FriendEmoji: {
        const name = d.n;
        const emoji = d.e;
        this.#gameUI.showFriendEmoji(name, emoji);
        break;
      }
      default: {
        console.log("Unhandled message type:", t, d);
        break;
      }
    }
  }

  #updatingFriendSpinners = false;

  startFriendUpdates() {
    if (this.#updatingFriendSpinners) {
      return;
    }
    this.#updatingFriendSpinners = true;
    window.requestAnimationFrame(this.friendSpinnersRotateOneFrame.bind(this));
  }

  stopFriendUpdates() {
    this.#updatingFriendSpinners = false;
  }

  #friends = [];

  async syncFriendsSpinners(friends) {
    this.#friends = friends;
  }

  friendSpinnerRotateOneFrame(dt, el, angle, velocity) {
    if (!el) {
      return;
    }

    if (dt <= 0 || Math.abs(velocity) < this.#settings.velocityMin) {
      return;
    }

    angle += velocity * dt;
    const deg = angle * (180 / Math.PI);
    el.style.transform = `rotate(${deg}deg)`;

    return angle;
  }

  #lastFriendSyncTime = 0;

  async friendSpinnersRotateOneFrame(time) {
    if (time <= 0) {
      return;
    }

    if (!this.#updatingFriendSpinners) {
      return;
    }

    if (this.#lastFriendSyncTime === 0) {
      this.#lastFriendSyncTime = time;
      window.requestAnimationFrame(this.friendSpinnersRotateOneFrame.bind(this));
      return;
    }

    const dt = time - this.#lastFriendSyncTime;
    this.#lastFriendSyncTime = time;

    const friendEls = document.getElementsByClassName("friend-avatar");
    for (let i = 0; i < friendEls.length; i++) {
      for (let j = 0; j < this.#friends.length; j++) {
        const el = friendEls[i];
        if (el.id === "friend-avatar-" + this.#friends[j].u) {
          const friend = this.#friends[j];
          const status = friend.s;
          const angle = status.a;
          const velocity = status.v;
          status.a = this.friendSpinnerRotateOneFrame(dt, el, Number.isNaN(angle) ? 0 : angle, velocity);
          break;
        }
      }
    }

    window.requestAnimationFrame(this.friendSpinnersRotateOneFrame.bind(this));
  }
}
