import {ClientMessageTypes} from "./backend-driver.js";

export class GameUI {
  /** @type {?GameMode} */
  #gameMode = null;
  /** @type {?AudioDriver} */
  #audioDriver = null;
  /** @type {?HapticsDriver} */
  #hapticsDriver = null;
  /** @type {?BackendDriver} */
  #backendDriver = null;
  /** @type {?DeviceOrientationDriver} */
  #orientationDriver = null;
  /** @type {?I18nDriver} */
  #i18nDriver = null;
  /** @type {?FxDriver} */
  #fxDriver = null;
  /** @type {NodeListOf<HTMLElement>}} */
  #screens = null;
  #activeScreen = "main-screen";
  /** @type {NodeListOf<HTMLElement>} */
  #navButtons = null;
  /** @type {?HTMLElement} */
  #userNameElement = null;
  /** @type {?HTMLElement} */
  #userBalanceElement = null;
  /** @type {?HTMLElement} */
  #userEnergyValueElement = null;
  /** @type {?HTMLElement} */
  #userEnergyMaxElement = null;
  /** @type {?HTMLElement} */
  #userEnergyBarElement = null;
  /** @type {?HTMLElement} */
  #userEnergyBarFillElement = null;
  /** @type {?HTMLElement} */
  #spinner = null;
  /** @type {?HTMLElement} */
  #multiplierElement = null;
  /** @type {?HTMLElement} */
  #multiplierTimerElement = null;
  /** @type {?HTMLElement} */
  #multiplierTimerMsElement = null;
  /** @type {boolean} */
  #boosterActive = false;
  /** @type {?HTMLElement} */
  #inviteButton = null;
  #dailyBoosts = {};
  #upgrades = {};

  /** @type {?HTMLElement} Container for purchasable upgrade items. */
  #storeUpgradeContainerElement = null;
  /** @type {?HTMLTemplateElement} Template for purchasable upgrade items. */
  #storeUpgradeItemTemplate = null;
  /** @type {?HTMLElement} Container for daily boost items. */
  #storeDailyBoostContainerElement = null;
  /** @type {?HTMLTemplateElement} Template for daily boost items. */
  #storeDailyBoostItemTemplate = null;
  /** @type {?HTMLElement} Confirmation button for store screen. */
  #storeConfirmButton = null;
  /** @type {?HTMLElement} Cancel button for store screen. */
  #storeCancelButton = null;
  /** @type {?HTMLElement} Store confirmation dialog. */
  #storeConfirmation = null;
  /** @type {boolean} Flag indicating whether the store confirmation dialog is transitioning. */
  #storeConfirmationTransitioning = false;
  /** @type {number} Delay for store confirmation dialog transition to block multiple clicks. */
  #storeConfirmationTransitionDelay = 333;
  /** @type {?HTMLElement} Selected item icon for store confirmation. */
  #storeConfirmationItemIcon = null;

  /** @type {?HTMLElement} Confirmation dialog item title. */
  #storeConfirmationItemTitle = null;
  /** @type {?HTMLElement} Confirmation dialog item description. */
  #storeConfirmationItemDescription = null;
  /** @type {?HTMLElement} Confirmation dialog item level. */
  #storeConfirmationItemLevel = null;
  /** @type {?HTMLElement} Confirmation dialog item separator. */
  #storeConfirmationItemSeparator = null;
  /** @type {?HTMLElement} Confirmation dialog level label. */
  #storeConfirmationItemLevelLabel = null;
  /** @type {?HTMLElement} Confirmation dialog level label. */
  #storeConfirmationItemCost = null;
  /** @type {?HTMLElement} */
  #storeConfirmationItemCostContainer = null;
  /** @type {?HTMLElement} Confirmation dialog level label. */
  #storeConfirmationTotalSpins = null;
  /** @type {?HTMLElement} */
  #storeConfirmationItemRemaining = null;

  /** @type {?HTMLElement} Audio control switch. */
  #audioControl = null;
  /** @type {?HTMLElement} Haptics control switch. */
  #hapticsControl = null;
  /** @type {?HTMLElement} Parallax control switch. */
  #parallaxControl = null;

  playerItems = [];
  activeSpinnerSkin = null;
  activeBackground = null;

  /** @type {Array<{message: string, type: string, timeout: number}>} */
  #popupMessages = [];
  /** @type {?HTMLElement} */
  #popup = null;
  /** @type {?HTMLElement} */
  #popupHeader = null;
  /** @type {?HTMLElement} */
  #popupClose = null;
  /** @type {?HTMLElement} */
  #popupFill = null;
  /** @type {?HTMLElement} */
  #ripple = null;

  /**
   * @param {AudioDriver} audioDriver
   * @param {HapticsDriver} hapticsDriver
   * @param {BackendDriver} backendDriver
   * @param {DeviceOrientationDriver} orientationDriver
   * @param {I18nDriver} i18nDriver
   * @param {FxDriver} fxDriver
   */
  async init(audioDriver = null, hapticsDriver = null, backendDriver = null, orientationDriver = null, i18nDriver = null, fxDriver = null) {
    this.#audioDriver = audioDriver;
    this.#hapticsDriver = hapticsDriver;
    this.#backendDriver = backendDriver;
    this.#orientationDriver = orientationDriver;
    this.#i18nDriver = i18nDriver;
    this.#fxDriver = fxDriver;

    this.#orientationDriver.setUI(this);

    this.#backendDriver.setPopupMessageCallback(this.handleShowMessage.bind(this));

    this.#initTelegramWebAppUI();
    this.#initNavigation();
    this.#initPopup();

    await this.#initMainScreen();
    await this.#initInviteButton();
    this.#initOptionsScreen();

    document.addEventListener("touchstart", (event) => {
      const touch = event.changedTouches[0];
      const x = touch.clientX;
      const y = touch.clientY;
      const ripple = document.createElement("div");
      ripple.classList.add("ripple");
      ripple.style.left = `${x}px`;
      ripple.style.top = `${y}px`;
      document.body.appendChild(ripple);
      setTimeout(() => {
        ripple.remove();
      }, 1000);
    });
  }

  async setGameMode(gameMode) {
    this.#gameMode = gameMode;
    await this.initStoreScreen();
    await this.#initItemShowcase();
  }

  #initTelegramWebAppUI() {
    if (window.Telegram && window.Telegram.WebApp) {
      if (typeof window.Telegram.WebApp.expand === "function") {
        if (!window.Telegram.WebApp.isExpanded) {
          window.Telegram.WebApp.expand();
        }

        if (typeof window.Telegram.WebApp.disableVerticalSwipes === "function") {
          window.Telegram.WebApp.disableVerticalSwipes();
        }
      }
    }
  }

  #initNavigation() {
    this.#navButtons = document.querySelectorAll("footer button");
    this.#screens = document.querySelectorAll("main .screen");

    for (let button of this.#navButtons) {
      button.onclick = this.handleNavigationButtonClick.bind(this);
    }
  }

  #initPopup() {
    this.#popup = document.getElementById("popup");
    this.#popupHeader = document.getElementById("popup-header");
    this.#popupClose = document.getElementById("popup-close");
    this.#popupFill = document.getElementById("popup-timeout-bar-fill");

    if (this.#popupClose) {
      this.#popupClose.onclick = () => {
        this.#popup.classList.add("hidden");
        this.#popupHeader.textContent = "";
      }
    }
  }

  addPopupMessage(message, type, timeout) {
    if (this.#popupMessages.length === 0) {
      this.#popupMessages.push({message, type, timeout});
      this.#showPopup();
    } else {
      this.#popupMessages.push({message, type, timeout});
    }
  }

  #showPopup() {
    if (this.#popupMessages.length === 0) {
      return;
    }

    const {message, type, timeout} = this.#popupMessages[0];

    if (this.#popup && this.#popupHeader) {
      this.#popupHeader.textContent = message;
      this.#popup.classList.remove("hidden");
      this.#popupFill.style.transition = "none";
      this.#popupFill.classList.remove("empty");

      this.#popup.classList.remove("success");
      this.#popup.classList.remove("warning");
      this.#popup.classList.remove("error");

      setTimeout(() => {
        if (type === "success") {
          this.#popup.classList.add("success");
        } else if (type === "error") {
          this.#popup.classList.add("error");
        } else if (type === "warning") {
          this.#popup.classList.add("warning");
        }

        this.#popupFill.style.transition = `width ${timeout || 3000}ms linear`;
        this.#popupFill.classList.add("empty");

        setTimeout(() => {
          this.#popup.classList.add("hidden");
          this.#popupHeader.textContent = "";

          if (this.#popupMessages.length > 0) {
            this.#popupMessages.shift();
            this.#showPopup();
          }
        }, timeout || 3000);
      }, 33);
    }
  }

  async #updateActiveScreen(screenId) {
    this.#navButtons.forEach(button => button.classList.remove("active"));
    this.#screens.forEach(screen => screen.classList.remove("active"));

    const targetButton = document.querySelector(`footer button[data-screen="${screenId}"]`);
    const targetScreen = document.getElementById(screenId);

    if (targetButton) {
      targetButton.classList.add("active");
    }

    if (targetScreen) {
      targetScreen.classList.add("active");
    }

    this.#activeScreen = screenId;

    switch (screenId) {
      case "friend-screen":
        this.#updateFriendsList();
        await this.#backendDriver.subscribeForFriendUpdates();
        this.#gameMode.startFriendUpdates();
        break;
      case "leaderboard-screen":
        this.#updateLeaderboard();
        await this.#backendDriver.unsubscribeFromFriendUpdates();
        this.#gameMode.stopFriendUpdates();
        break;
      default:
        await this.#backendDriver.unsubscribeFromFriendUpdates();
        break;
    }
  }

  async goToScreen(screenId) {
    await this.#updateActiveScreen(screenId);
  }

  /** @param {Event} event */
  async handleNavigationButtonClick(event) {
    const button = event.target.closest("button");
    if (!button) return;

    const targetScreenId = button.dataset['screen'];
    if (!targetScreenId) return;

    if (this.#hapticsDriver) {
      this.#hapticsDriver.playNavigationHaptic();
    }

    await this.goToScreen(targetScreenId);
  }

  handleShowMessage(type, message, timeout) {
    this.addPopupMessage(message, type, timeout);
  }

  async #initMainScreen() {
    this.#userNameElement = document.getElementById("main-screen-username");
    if (this.#userNameElement) {
      if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initDataUnsafe && window.Telegram.WebApp.initDataUnsafe.user) {
        this.#userNameElement.textContent = `${window.Telegram.WebApp.initDataUnsafe.user.first_name} ${window.Telegram.WebApp.initDataUnsafe.user.last_name}`;
      } else {
        this.#userNameElement.textContent = "";
      }
    }

    this.#userBalanceElement = document.getElementById("balance");
    this.#spinner = document.getElementById("spinner");
    this.#userEnergyValueElement = document.getElementById("main-screen-energy-value");
    this.#userEnergyMaxElement = document.getElementById("main-screen-energy-max");
    this.#userEnergyBarElement = document.getElementById("main-screen-energy-bar");
    this.#userEnergyBarFillElement = document.getElementById("main-screen-energy-bar-fill");
    this.#multiplierElement = document.getElementById("energy-boost-multiplier");
    this.#multiplierTimerElement = document.getElementById("energy-boost-timer");
    this.#multiplierTimerMsElement = document.getElementById("energy-boost-timer-ms");
  }

  setBoosterActive(active) {
    this.#boosterActive = active;

    if (active) {
      if (this.#spinner && this.#spinner.classList) {
        this.#spinner.classList.add("boosted");
      }

      if (this.#userEnergyBarElement && this.#userEnergyBarElement.classList) {
        this.#userEnergyBarElement.classList.add("boosted");
      }

      if (this.#multiplierElement && this.#multiplierElement.classList) {
        this.#multiplierElement.classList.add("boosted");
        this.#multiplierElement.textContent = `x${this.#gameMode.getMultiplier()}`;
      }

      if (this.#multiplierTimerElement && this.#multiplierTimerElement.classList) {
        this.#multiplierTimerElement.classList.add("boosted");
        this.animateBoostTimer(7);
      }
    } else {
      if (this.#spinner && this.#spinner.classList) {
        this.#spinner.classList.remove("boosted");
      }

      if (this.#userEnergyBarElement && this.#userEnergyBarElement.classList) {
        this.#userEnergyBarElement.classList.remove("boosted");
      }
    }

    if (this.#fxDriver) {
      this.#fxDriver.setBoostActive(active);
    }
  }

  /** @param {number|string} score */
  updateScore(score) {
    if (!this.#userBalanceElement) {
      return;
    }

    if (typeof score === 'string') {
      score = parseInt(score, 10);
    }

    const oldScore = parseInt(this.#userBalanceElement.textContent, 10) || 0;
    const newScore = score ? score : 0;
    const delta = newScore - oldScore;

    const duration = 0.66;
    const frameRate = 60;
    const totalFrames = duration * frameRate;
    let currentFrame = 0;

    const animateScore = () => {
      if (currentFrame <= totalFrames) {
        const interpolatedScore = Math.round(oldScore + (delta * (currentFrame / totalFrames)));
        this.#userBalanceElement.textContent = `${interpolatedScore}`;
        currentFrame++;
        requestAnimationFrame(animateScore);
      } else {
        this.#userBalanceElement.textContent = `${newScore}`;
      }
    };

    animateScore();
  }

  spawnScoreParticle(number) {
    if (this.#activeScreen !== "main-screen") {
      return;
    }
    this.#fxDriver.showScore(this.#spinner, number, this.#boosterActive);
  }

  hideSpinSpeedEffect() {
    this.#fxDriver.hideSpinSpeedEffect();
  }

  showSpinSpeedEffect(speed, tier) {
    if (this.#activeScreen !== "main-screen") {
      return;
    }
    this.#fxDriver.showSpinSpeedEffect(speed, tier);
  }

  animateBoostTimer(duration) {
    if (this.#multiplierTimerElement) {
      const startTime = performance.now();

      const animateTimer = () => {
        const t = performance.now();
        const et = (t - startTime) / 1000;
        const rt = Math.max(duration - et, 0);

        const st = Math.floor(rt);
        const mst = Math.floor((rt - st) * 100);

        this.#multiplierTimerElement.textContent = `${st.toFixed(0)}.`;
        this.#multiplierTimerMsElement.textContent = `${mst.toString().padStart(2, '0')}`;

        if (rt > 0) {
          requestAnimationFrame(animateTimer);
        } else {
          this.#multiplierTimerElement.textContent = "0.";
          this.#multiplierTimerMsElement.textContent = "00";
        }
      };

      animateTimer();
    }
  }

  updateEnergy(current, max) {
    if (this.#userEnergyValueElement) {
      this.#userEnergyValueElement.textContent = `${current}`;
      const oldEnergy = parseInt(this.#userEnergyValueElement.textContent, 10) || 0;
      const delta = current - oldEnergy;
      const durationEn = 0.66; // Duration of the animation in milliseconds
      const frameRate = 60; // Frames per second
      const totalFrames = durationEn * frameRate;
      let currentFrame = 0;

      const animateEnergy = () => {
        if (currentFrame <= totalFrames) {
          const interpolatedScore = Math.round(oldEnergy + (delta * (currentFrame / totalFrames)));
          this.#userEnergyValueElement.textContent = `${interpolatedScore}`;
          currentFrame++;
          requestAnimationFrame(animateEnergy);
        } else {
          this.#userEnergyValueElement.textContent = `${current}`;
        }
      };

      animateEnergy();
    }

    if (this.#userEnergyMaxElement) {
      this.#userEnergyMaxElement.textContent = `${max}`;
    }

    if (this.#userEnergyBarFillElement) {
      this.#userEnergyBarFillElement.style.width = `${(current / max) * 100}%`;
    }
  }

  async #initInviteButton() {
    this.#inviteButton = document.getElementById("friend-invite-button");

    // Set up event listeners for share button.

    if (this.#inviteButton) {
      this.#inviteButton.onclick = async () => {
        if (window.Telegram && window.Telegram.WebApp) {
          let referralCode = localStorage.getItem('referralCode');
          if (null == referralCode || 'string' !== typeof referralCode || 0 === referralCode.length) {
            referralCode = await this.#backendDriver.getCode();
            if (null == referralCode || 'string' !== typeof referralCode || 0 === referralCode.length) {
              console.error('Failed to get referral code');
              return;
            }
          }
          const text = await this.#i18nDriver.translate("invite-share-text");
          const url = `https://t.me/SpinnerSpinsDoughRollsBot/Spinner?startapp=${referralCode}`;
          window.Telegram.WebApp.openTelegramLink(`https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`);
        }
      }
    }
  }

  /**
   * Renders a friend list in the UI.
   */
  #updateFriendsList() {
    this.#backendDriver.getFriends()
      .then(response => {
        //region Total Friends

        let totalFriendNum = response.totalFriends;
        const totalFriendsElement = document.getElementById('friend-screen-total-friends');
        if (totalFriendsElement) {
          if (null == totalFriendNum) {
            totalFriendsElement.textContent = '0';
          } else {
            totalFriendsElement.textContent = totalFriendNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          }
        }

        //endregion

        //region Total Friend Bonus

        const totalFriendBonus = response.totalRewards;
        const totalFriendBonusElement = document.getElementById('friend-screen-total-bonus');
        if (totalFriendBonusElement) {
          if (null == totalFriendBonus || 'number' !== typeof totalFriendBonus) {
            totalFriendBonusElement.textContent = '0';
          } else {
            totalFriendBonusElement.textContent = totalFriendBonus.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          }
        }

        //endregion

        //region Friends List

        const friends = response.friends;
        const itemTemplate = document.getElementById('template-friend-item');
        const container = document.getElementById('friend-list');

        container.innerHTML = '';

        if (friends != null) {
          for (let i = 0; i < friends.length; i++) {
            if (null == friends[i]) {
              continue;
            }

            const item = itemTemplate.content.cloneNode(true);
            if (null == friends[i]["tg_name"]) {
              item.querySelector('.name').textContent = this.#i18nDriver.translate('anonymous-tg-username');
            } else {
              item.querySelector('.name').textContent = friends[i]["tg_name"];
            }

            if (null == friends[i]["spins"] || 'number' !== typeof friends[i]["spins"]) {
              item.querySelector('.balance > span').textContent = '0';
            } else {
              item.querySelector('.balance > span').textContent = friends[i]["spins"];
            }

            const avatar = item.querySelector('.friend-avatar');
            if (avatar) {
              avatar.id = `friend-avatar-${friends[i]["id"]}`;
              avatar.dataset['id'] = friends[i]["id"];
            }

            let debounce = false;
            avatar.onclick = async () => {
              const emojiList = ['👋', '👍', '👏', '🎉', '🎊', '🥳', '🤩', '🥰', '😍', '😘', '😊', '😎', '😇', '😂', '😜', '😋'];
              const emoji = emojiList[Math.floor(Math.random() * emojiList.length)];
              if (!debounce) {
                await this.#gameMode.sendMessageFriendEmoji(friends[i]["id"], emoji);
                debounce = true;
                setTimeout(() => {
                  debounce = false;
                }, 1000);
              }
            }

            container.appendChild(item);
          }
        }

        //endregion
      });
  }

  showFriendEmoji(name, emoji) {
    const emojiContainerEl = document.getElementById('friend-emoji-container');
    const emojiTemplateEl = document.getElementById('template-friend-emoji');
    if (emojiContainerEl) {
      const emojiEl = emojiTemplateEl.content.cloneNode(true);
      emojiEl.querySelector('.emoji').textContent = emoji;
      emojiEl.querySelector('.name').textContent = name;
      emojiEl.querySelector('.emoji-wrapper').style.left = `${Math.random() * 25}%`;
      const wrapper = emojiEl.querySelector('.emoji-wrapper');
      emojiContainerEl.appendChild(wrapper);
      setTimeout(() => {
        wrapper.remove();
      }, 7000);
    }
  }

  updateReferralReward(base, premium) {
    console.log('updateReferralReward', base, premium);
    const baseEl = document.getElementById('referral-reward-base');
    const premiumEl = document.getElementById('referral-reward-premium');

    if (baseEl) {
      baseEl.textContent = base;
    }

    if (premiumEl) {
      premiumEl.textContent = premium;
    }
  }

  /**
   * Renders Leaderboard in the UI.
   */
  #updateLeaderboard() {
    this.#backendDriver.getLeaderboard()
      .then(response => {
        //region Total Players

        let totalPlayerNum = response["totalPlayers"];
        const totalPlayersElement = document.getElementById('leaderboard-total-players');
        if (totalPlayersElement) {
          if (null == totalPlayerNum || 'number' !== typeof totalPlayerNum) {
            totalPlayersElement.textContent = '0';
          } else {
            if (totalPlayerNum > 1000) {
              totalPlayersElement.textContent = totalPlayerNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            } else {
              totalPlayersElement.textContent = totalPlayerNum;
            }
          }
        }

        //endregion

        //region Leaderboard List

        const leaderboard = response.leaderboard;
        const itemTemplate = document.getElementById('template-leaderboard-item');
        const container = document.getElementById('leaderboard-list');

        container.innerHTML = '';

        for (let i = 0; i < leaderboard.length; i++) {
          if (null == leaderboard[i]) {
            continue;
          }

          const item = itemTemplate.content.cloneNode(true);
          item.querySelector('.rating').textContent = i + 1;
          if (null == leaderboard[i]["tg_name"]) {
            item.querySelector('.name').textContent = 'Anonymous'; // todo: i18n
          } else {
            item.querySelector('.name').textContent = leaderboard[i]["tg_name"];
          }

          if (null == leaderboard[i]["spins"]) {
            item.querySelector('.balance > span').textContent = '0';
          } else {
            item.querySelector('.balance > span').textContent = leaderboard[i]["spins"];
          }

          container.appendChild(item);
        }

        //endregion
      })
      .catch(error => {
        console.error('Failed to load leaderboard', error);
      });
  }

  async #initItemShowcase() {

    //this.allItems = await this.#backendDriver.getAllItems();
    //this.playerItems = await this.#backendDriver.getPlayerItems();

    let elements = this.#storeDailyBoostContainerElement.querySelectorAll("a.item.inventory-item")
    for (let element of elements) {
      element.remove();
    }

    let itemShowcase = document.getElementById('inventory-item-container');
    let itemTemplate = document.getElementById('template-inventory-item');

    // check if player items is an array
    if (Array.isArray(this.playerItems)) {
      if (this.playerItems.length > 0) {
        for (let [key, _] of this.playerItems) {
          const itemElement = itemTemplate.content.cloneNode(true);
          //await this.#i18nDriver.applyTranslationsToElement(itemElement);

          const rootElement = itemElement.querySelector('a.item.inventory-item');
          const iconElement = itemElement.querySelector('.icon');
          if (iconElement) {
            iconElement.src = "/test_Bck_icon.jpg";
          }
          itemShowcase.appendChild(itemElement);

          rootElement.onclick = () => {
            this.applySkin(key);
          }
        }
      }
    }
  }

  async applySkin(itemID) {
    let item = this.playerItems.get(itemID);

    if (item == null) {
      return;
    }

    let id = '00000000-0000-0000-0000-000000000000';
    let t = 0;
    switch (item.type) {
      case 0:
        t = 0;
        if (this.activeSpinnerSkin != null) {
          if (this.activeSpinnerSkin === item.id) {
            //reset skin to default
            this.applySpinnerSkin("/spinner.png");
            this.activeSpinnerSkin = '00000000-0000-0000-0000-000000000000';

            break;
          } else {
            //apply new skin
            this.activeSpinnerSkin = item.id;
            this.applySpinnerSkin(item["item_url"]);
            id = item.id;
            break;
          }
        } else {
          this.activeSpinnerSkin = item.id;
          this.applySpinnerSkin(item["item_url"]);
          id = item.id;
          break;
        }
      case 1:
        t = 1;
        if (this.activeBackground != null) {
          if (this.activeBackground === item.id) {
            //reset skin to default
            this.activeBackground = '00000000-0000-0000-0000-000000000000';
            this.applyBackground("/background.jpg");
            break;
          } else {
            //apply new skin
            this.activeBackground = item.id;
            this.applyBackground(item["item_url"]);
            id = item.id;
            break;
          }
        } else {
          this.activeBackground = item.id;
          this.applyBackground(item["item_url"]);
          id = item.id;
          break;
        }
    }

    let data = {
      t: ClientMessageTypes.TOGGLE_SKIN,
      d: {
        id: id,
        t: t
      }
    };

    await this.#backendDriver.sendDataToWS(JSON.stringify(data));
  }

  applySpinnerSkin(skinPath) {
    this.#spinner.src = `${skinPath}`;
  }

  applyBackground(skinPath) {
    document.body.style.backgroundImage = `url(${skinPath})`;
  }

  async initStoreScreen() {
    await this.#initDailyBoosts();
    await this.#initUpgradeListItems();
    this.#initStoreConfirmation();
  }

  async refreshStore() {
    await this.initStoreScreen();
  }

  async #initDailyBoosts() {
    this.#storeDailyBoostContainerElement = document.getElementById("daily-item-container");
    this.#storeDailyBoostItemTemplate = document.getElementById("template-daily-item");

    this.#dailyBoosts = await this.#backendDriver.getDailyBoosts();

    if (this.#storeDailyBoostContainerElement && this.#dailyBoosts) {
      let elements = this.#storeDailyBoostContainerElement.querySelectorAll("a.item.store-item")
      for (let element of elements) {
        element.remove();
      }

      for (let key of Object.keys(this.#dailyBoosts)) {
        if (!this.#dailyBoosts.hasOwnProperty(key)) {
          continue;
        }

        const boost = this.#dailyBoosts[key];
        boost.key = key;

        const itemEl = this.#storeDailyBoostItemTemplate.content.cloneNode(true);
        await this.#i18nDriver.applyTranslationsToElement(itemEl);

        const rootEl = itemEl.querySelector('a.item.store-item');
        if (rootEl) {
          rootEl.dataset['id'] = boost.id;
        }

        const avlbEl = itemEl.querySelector('.daily-available');
        if (avlbEl) {
          avlbEl.textContent = `${boost.remaining > 0 ? boost.remaining : 0}`;
        }

        const maxEl = itemEl.querySelector('.daily-max');
        if (maxEl) {
          maxEl.textContent = `${boost.max}`;
        }

        const iconElement = itemEl.querySelector('.daily-icon');
        if (iconElement) {
          iconElement.textContent = boost.icon;
        }

        const headElement = itemEl.querySelector('.daily-header');
        if (headElement) {
          headElement.textContent = await this.#i18nDriver.translate(boost.title);
        }

        const descElement = itemEl.querySelector('.daily-description');
        if (descElement) {
          descElement.textContent = await this.#i18nDriver.translate(boost.description);
        }

        if (boost.remaining > 0) {
          rootEl.onclick = () => {
            this.#showStoreConfirmation(rootEl, boost,
              () => this.#onDailyBoostPurchaseConfirmed(boost),
              this.#onDailyBoostPurchaseCancelled.bind(this));
          }
        } else {
          rootEl.style.filter = `saturate(${0.5}%)`;
        }

        this.#storeDailyBoostContainerElement.appendChild(itemEl);
      }
    }
  }

  async #initUpgradeListItems() {
    this.#storeUpgradeContainerElement = document.getElementById("upgrade-item-container");
    this.#storeUpgradeItemTemplate = document.getElementById("template-upgrade-item");

    this.#upgrades = await this.#backendDriver.getUpgrades();

    if (this.#storeUpgradeContainerElement && this.#upgrades) {
      let elements = this.#storeUpgradeContainerElement.querySelectorAll("a.item.store-item")
      for (let element of elements) {
        element.remove();
      }

      for (let key of Object.keys(this.#upgrades)) {
        if (!this.#upgrades.hasOwnProperty(key)) {
          continue;
        }

        this.#upgrades[key].key = key;
        const upgrade = this.#upgrades[key];

        const itemEl = this.#storeUpgradeItemTemplate.content.cloneNode(true);
        await this.#i18nDriver.applyTranslationsToElement(itemEl);

        const rootEl = itemEl.querySelector('a.item.store-item');
        if (rootEl) {
          rootEl.dataset['id'] = upgrade.id;
        }

        if (upgrade.level === this.#gameMode.getMaxUpgradeLevel(key)) {
          const levelElement = itemEl.querySelector('.upgrade-level');
          if (levelElement) {
            levelElement.textContent = "MAX";
            rootEl.style.filter = `saturate(${0.5}%)`;
          }
        } else {
          const headerElement = itemEl.querySelector('.upgrade-header');
          if (headerElement) {
            headerElement.textContent = await this.#i18nDriver.translate(upgrade.title);
          }

          const valueElement = itemEl.querySelector('.upgrade-value');
          if (valueElement) {
            valueElement.textContent = upgrade.cost;
          }

          const levelElement = itemEl.querySelector('.upgrade-level');
          if (levelElement) {
            levelElement.textContent = upgrade.level;
          }

          rootEl.onclick = () => {
            this.#showStoreConfirmation(rootEl, upgrade,
              () => this.#onUpgradePurchaseConfirmed(upgrade),
              this.#onUpgradePurchaseCancelled.bind(this));
          }
        }

        const iconElement = itemEl.querySelector('.upgrade-icon');
        if (iconElement) {
          iconElement.textContent = upgrade.icon;
        }

        this.#storeUpgradeContainerElement.appendChild(itemEl);
      }
    }
  }

  #initStoreConfirmation() {
    this.#storeConfirmation = document.getElementById("store-confirmation");
    if (this.#storeConfirmation) {
      this.#storeConfirmation.classList.remove("active");
      this.#storeConfirmation.onclick = () => {
        this.#hideStoreConfirmation();
      }
    }

    this.#storeConfirmButton = document.getElementById("store-screen-confirm-button");
    this.#storeCancelButton = document.getElementById("store-screen-cancel-button");
    this.#storeConfirmationItemIcon = document.getElementById("store-confirmation-icon");
    this.#storeConfirmationItemTitle = document.getElementById("store-confirmation-header");
    this.#storeConfirmationItemSeparator = document.getElementById("store-confirmation-separator");
    this.#storeConfirmationItemLevel = document.getElementById("store-confirmation-level");
    this.#storeConfirmationItemLevelLabel = document.getElementById("store-confirmation-level-label");
    this.#storeConfirmationItemDescription = document.getElementById("store-confirmation-description");
    this.#storeConfirmationItemCost = document.getElementById("store-confirmation-cost");
    this.#storeConfirmationItemCostContainer = document.getElementById("store-confirmation-cost-container");
    this.#storeConfirmationTotalSpins = document.getElementById("store-confirmation-total-spins");
    this.#storeConfirmationItemRemaining = document.getElementById("store-confirmation-remaining");
  }

  async #showStoreConfirmation(el, item, confirmFunc, cancelFunc) {
    if (this.#storeConfirmationTransitioning) {
      return;
    }

    if (item.title) {
      this.#storeConfirmationItemTitle.textContent = await this.#i18nDriver.translate(item.title);
    } else {
      this.#storeConfirmationItemTitle.textContent = "";
    }

    let showSeparator = false;

    if (item.cost) {
      this.#storeConfirmationItemCost.textContent = item.cost;
      this.#storeConfirmationTotalSpins.textContent = `${this.#gameMode.getTotalSpins()}`;
      if (item.cost <= this.#gameMode.getTotalSpins()) {
        this.#storeConfirmationTotalSpins.classList.remove("insufficient");
        this.#storeConfirmationTotalSpins.classList.add("sufficient");
      } else {
        this.#storeConfirmationTotalSpins.classList.add("insufficient");
        this.#storeConfirmationTotalSpins.classList.remove("sufficient");
      }
      this.#storeConfirmationItemCost.classList.add("active");
      this.#storeConfirmationItemRemaining.classList.remove("active");
      this.#storeConfirmationItemCostContainer.classList.add("active");
      showSeparator = true;
    } else {
      this.#storeConfirmationItemCost.textContent = "";
      this.#storeConfirmationTotalSpins.textContent = "";
      this.#storeConfirmationItemCost.classList.remove("active");
      this.#storeConfirmationItemRemaining.classList.add("active");
      this.#storeConfirmationItemCostContainer.classList.remove("active");
    }

    if (item.level != null) {
      this.#storeConfirmationItemLevel.textContent = item.level ? "" + (parseInt(item.level, 10) + 1) : 0;
      this.#storeConfirmationItemLevel.classList.add("active");
      this.#storeConfirmationItemLevelLabel.classList.add("active");
      showSeparator &= true;
    } else {
      this.#storeConfirmationItemLevel.textContent = "";
      this.#storeConfirmationItemLevel.classList.remove("active");
      this.#storeConfirmationItemLevelLabel.classList.remove("active");
      showSeparator &= false;
    }

    if (showSeparator) {
      this.#storeConfirmationItemSeparator.classList.add("active");
    } else {
      this.#storeConfirmationItemSeparator.classList.remove("active");
    }

    this.#storeConfirmationItemDescription.textContent = item.description ? item.description : "";
    this.#storeConfirmationItemIcon.textContent = item.icon ? item.icon : "";
    this.#storeConfirmationTransitioning = true;

    setTimeout(() => {
      this.#storeConfirmationTransitioning = false;
    }, this.#storeConfirmationTransitionDelay);

    if (this.#storeConfirmation) {
      this.#storeConfirmation.classList.remove("hidden");
      this.#storeConfirmation.classList.add("active");
    }

    if (this.#storeConfirmButton) {
      this.#storeConfirmButton.onclick = () => {
        confirmFunc();
        this.#hideStoreConfirmation();
      }
    }

    if (this.#storeCancelButton) {
      this.#storeCancelButton.onclick = () => {
        cancelFunc();
        this.#hideStoreConfirmation();
      }
    }
  }

  #hideStoreConfirmation() {
    if (this.#storeConfirmationTransitioning) {
      return;
    }

    this.#storeConfirmationTransitioning = true;

    if (this.#storeConfirmation) {
      this.#storeConfirmation.classList.remove("active");

      setTimeout(() => {
        this.#storeConfirmation.classList.add("hidden");
        this.#storeConfirmationTransitioning = false;
      }, this.#storeConfirmationTransitionDelay);
    }

    if (this.#storeConfirmButton) {
      this.#storeConfirmButton.onclick = null;
    }

    if (this.#storeCancelButton) {
      this.#storeCancelButton.onclick = null;
    }
  }

  async #onDailyBoostPurchaseConfirmed(boost) {
    this.#hapticsDriver.playNotificationSuccessHaptic();
    const result = await this.#backendDriver.activateDailyBoost(boost.key);
    if (result.error) {
      this.addPopupMessage(result.error, "error", 1000);
      return await this.refreshStore();
    } else {
      this.addPopupMessage("Daily boost activated", "success", 1000);
      if (boost.key === 'multiplier') {
        await this.goToScreen('main-screen');
        this.#gameMode.activateMultiplierBoost();
      }
    }

    return await this.refreshStore();
  }

//endregion

//region Options Screen

  #onDailyBoostPurchaseCancelled() {
    this.addPopupMessage("Daily boost cancelled", "", 1000);
  }

  async #onUpgradePurchaseConfirmed(upgrade) {
    this.#hapticsDriver.playNotificationSuccessHaptic();

    const result = await this.#backendDriver.buyUpgrade(upgrade.key);
    if (result.error) {
      this.addPopupMessage(result.error, "error", 1000);
    } else {
      this.#gameMode.setTotalSpins(result.spins);
      this.updateScore(result.spins);
      this.addPopupMessage("Upgrade purchased", "success", 1000);
    }
    await this.refreshStore();
  }

  #onUpgradePurchaseCancelled() {
    this.addPopupMessage("Upgrade cancelled", "", 1000);
  }

  #initOptionsScreen() {
    this.#hapticsControl = document.getElementById("settings-screen-switch-haptics");
    this.#audioControl = document.getElementById("settings-screen-switch-sound");
    this.#parallaxControl = document.getElementById("settings-screen-switch-parallax");

    // Set up event listeners for haptics control.
    if (this.#hapticsControl) {
      this.#hapticsControl.onclick = () => {
        this.#hapticsDriver.toggleHaptics();
        this.#updateHapticsUI();
      }
    } else {
      console.warn("Haptics control not found!");
    }

    // Set up event listeners for audio control.
    if (this.#audioControl) {
      this.#audioControl.onclick = () => {
        this.#audioDriver.toggleSound();
        this.#updateAudioUI();
      }
    } else {
      console.warn("Audio control not found!");
    }

    // Set up event listeners for parallax control.
    if (this.#parallaxControl) {
      this.#parallaxControl.onclick = () => {
        this.#orientationDriver.requestPermission();
        this.#orientationDriver.toggleParallax();
        this.#updateParallaxUI();
      }
    } else {
      // console.warn("Parallax control not found!");
    }

    // Update UI based on current settings.
    this.#updateHapticsUI();
    this.#updateAudioUI();
    this.#updateParallaxUI()
  }

  /**
   * Updates the audio control UI based on the current audio settings.
   */
  #updateAudioUI() {
    if (!this.#audioDriver) {
      return;
    }

    if (this.#audioDriver.isEnabled()) {
      this.#audioControl.classList.add("active");
      this.#audioControl.checked = true;
    } else {
      this.#audioControl.classList.remove("active");
      this.#audioControl.checked = false;
    }
  }

  /**
   * Updates the haptics control UI based on the current haptics settings.
   */
  #updateHapticsUI() {
    if (!this.#hapticsDriver) {
      return;
    }

    if (this.#hapticsDriver.isEnabled()) {
      this.#hapticsControl.classList.add("active");
      this.#hapticsControl.checked = true;
    } else {
      this.#hapticsControl.classList.remove("active");
      this.#hapticsControl.checked = false;
    }
  }

  /**
   * Updates the parallax control UI based on the current parallax settings.
   */
  #updateParallaxUI() {
    if (!this.#parallaxControl) {
      return;
    }

    if (this.#orientationDriver.isEnabled()) {
      this.#parallaxControl.classList.add("active");
      this.#parallaxControl.checked = true;
    } else {
      this.#parallaxControl.classList.remove("active");
      this.#parallaxControl.checked = false;
    }
  }

//endregion

}
