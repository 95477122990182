import "bootstrap-icons/font/bootstrap-icons.css";
import './style.css';
import {AudioDriver} from "./audio-driver.js";
import {BackendDriver} from './backend-driver.js';
import {DeviceOrientationDriver} from './device-orientation-driver.js';
import {HapticsDriver} from './haptics-driver.js';
import {GameUI} from './game-ui.js';
import {GameMode} from './game-mode.js';
import {I18nDriver} from "./i18n-driver.js";
import {FxDriver} from "./fx-driver.js";
import {SentryDriver} from "./sentry-driver.js";

window.Telegram = window.Telegram || {
  WebApp: {
    platform: null,
    initDataUnsafe: {
      user: {
        languageCode: '',
        first_name: '',
        last_name: '',
      }
    },
    disableVerticalSwipes: () => {
    }
  }
};

window.process = window.process || {env: {NODE_ENV: 'production'}};

window.addEventListener("DOMContentLoaded", async () => {
  const sentryDriver = new SentryDriver();
  sentryDriver.init();

  if (window.Telegram && window.Telegram.WebApp) {
    // Allow only mobile devices.
    if (typeof window.Telegram.WebApp.platform === "string") {
      switch (window.Telegram.WebApp.platform) {
        case 'android':
        case 'ios':
          break;
        default:
          // Replace body contents with a message.
          // document.body.innerHTML = `<p class="mobile-only">Spinner is available only for mobile devices.</p>`;
          break;
      }
    }
  } else {
    // Replace body contents with a message.
    // document.body.innerHTML = `<p class="mobile-only">Spinner is available only for mobile devices.</p>`;
  }

  const backendDriver = new BackendDriver();
  await backendDriver.init();

  const i18nDriver = new I18nDriver();
  await i18nDriver.init(backendDriver);

  const fxDriver = new FxDriver();
  fxDriver.init();

  const audioDriver = new AudioDriver();
  audioDriver.init();

  const hapticsDriver = new HapticsDriver();
  hapticsDriver.init();

  const deviceOrientationDriver = new DeviceOrientationDriver();
  deviceOrientationDriver.init();

  const gameUI = new GameUI();
  await gameUI.init(audioDriver, hapticsDriver, backendDriver, deviceOrientationDriver, i18nDriver, fxDriver);

  const gameMode = new GameMode();
  await gameMode.init(gameUI, audioDriver, hapticsDriver, backendDriver, fxDriver);


  (function() {


    const logElement = document.getElementById('log-output');

    const originalConsoleLog = console.log;
    const originalConsoleWarning = console.warn;
    const originalConsoleError = console.error;
    /*
        console.log = function(...args) {
          originalConsoleLog.apply(console, args);

          const message = JSON.stringify(args);

          backendDriver.sendDataToWS(message);

          const logLine = document.createElement('div');
          logLine.textContent = message;
          logElement.appendChild(logLine);

          logElement.scrollTop = logElement.scrollHeight;
        };

        console.error = function(...args) {
          originalConsoleError.apply(console, args);

          // const message = args.map(arg => {
          //   return typeof arg === 'object' ? JSON.stringify(arg, null, 2) : String(arg);
          // }).join(' ');

          const message = JSON.stringify(args);

          const logLine = document.createElement('div');
          logLine.textContent = message;
          logLine.style.color = 'red';
          logElement.appendChild(logLine);

          logElement.scrollTop = logElement.scrollHeight;
        };
    */
    /*console.warn = function(...args) {
      originalConsoleWarning.apply(console, args);

      const message = args.map(arg => {
        return typeof arg === 'object' ? JSON.stringify(arg, null, 2) : String(arg);
      }).join(' ');

      const logLine = document.createElement('div');
      logLine.textContent = message;
      logLine.style.color = 'orange';
      logElement.appendChild(logLine);

      logElement.scrollTop = logElement.scrollHeight;
    };*/
  })();

});
