import {ClientMessageTypes} from "./backend-driver.js";

class SpinnerTouch {
  static MAX_PATH_LENGTH = 100;
  static MIN_DISTANCE = 2;
  static MIN_VELOCITY = 0.0001;

  /** @param {Touch} touch */
  constructor(touch) {
    this.time = new Date().getTime();
    this.id = touch.identifier;
    this.x = touch.clientX;
    this.y = touch.clientY;
    this.path = [{x: touch.clientX, y: touch.clientY, time: this.time}];
  }

  /** @param {Touch} touch */
  update(touch) {
    const dx = touch.clientX - this.x;
    const dy = touch.clientY - this.y;
    const d = Math.sqrt(dx * dx + dy * dy);

    if (d > SpinnerTouch.MIN_DISTANCE) {
      this.x = touch.clientX;
      this.y = touch.clientY;
      const time = new Date().getTime();
      if (this.path.length >= SpinnerTouch.MAX_PATH_LENGTH) {
        this.path.shift();
      }
      this.path.push({x: touch.clientX, y: touch.clientY, time: time});
    }
  }

  /** Unwinds the touch dropping the last full circle path. */
  unwind() {
    const s = this.path[this.path.length - 1];
    let i = this.path.length - 2;
    while (i >= 0) {
      const t = this.path[i];
      const dx = s.x - t.x;
      const dy = s.y - t.y;
      const d = Math.sqrt(dx * dx + dy * dy);
      if (d > SpinnerTouch.MIN_DISTANCE) {
        break;
      }
      i--;
    }
    this.path = this.path.slice(i + 1);
  }



  getInitialVelocity() {
    if (this.path.length >= 2) {
      const n = this.path.length - 1;

      const dt = this.path[n].time - this.path[n - 1].time;

      if (Math.abs(dt) < SpinnerTouch.MIN_VELOCITY) {
        return 0;
      }

	  const centerX = window.innerWidth / 2;
	  const centerY = window.innerHeight / 2;
	  const startAngle = Math.atan2(this.path[n-1].y - centerY, this.path[n-1].x - centerX) * (180 / Math.PI);
	  const EndAngle = Math.atan2(this.path[n].y - centerY, this.path[n].x - centerX) * (180 / Math.PI);
	  let angle = (EndAngle - startAngle);
		if (angle > 180) {
			angle -= 360;
		} else if (angle < -180) {
			angle += 360;
		}
	  	// console.log("Start X: " + this.path[n-1].x + " Start Y: " + this.path[n-1].y);
		// console.log("End X: " + this.path[n].x + " End Y: " + this.path[n].y);
		// console.log("Start Angle: " + startAngle);
		// console.log("End Angle: " + EndAngle);
		// console.log("Angle: " + angle);
	  let translatedX1 = this.path[n-1].x - centerX;
	  let translatedY1 = this.path[n-1].y - centerY;
	  let translatedX2 = this.path[n].x - centerX;
	  let translatedY2 = this.path[n].y - centerY;
		const dx = translatedX2 - translatedX1;
		const dy = translatedY2 - translatedY1;

		const velocity = Math.sqrt(dx * dx + dy * dy)  / 1000;
		const direction = Math.atan2(dy, dx);


			return velocity * Math.sign(angle);

    }

    return 0;
  }
}

class SpinnerPoint {
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }

  translate(x, y) {
    return new SpinnerPoint(this.x + x, this.y + y);
  }

  rotate(angle) {
    const x = this.x * Math.cos(angle) - this.y * Math.sin(angle);
    const y = this.x * Math.sin(angle) + this.y * Math.cos(angle);
    return new SpinnerPoint(x, y);
  }

  scale(factor) {
    return new SpinnerPoint(this.x * factor, this.y * factor);
  }

  /** @param {SpinnerPoint} center
   * @param {number} radius */
  isInCircle(center, radius) {
    const dx = this.x - center.x;
    const dy = this.y - center.y;
    return dx * dx + dy * dy <= radius * radius;
  }

  /** @param {SpinnerPoint} topLeft
   * @param {SpinnerPoint} bottomRight */
  isInRectangle(topLeft, bottomRight) {
    return topLeft.x <= this.x && this.x <= bottomRight.x && topLeft.y <= this.y && this.y <= bottomRight.y;
  }

  /** @param {SpinnerPoint[]} points */
  isInPolygon(points) {
    let inside = false;
    const {x, y} = this;
    for (let i = 0, j = points.length - 1; i < points.length; j = i++) {
      const xi = points[i].x, yi = points[i].y;
      const xj = points[j].x, yj = points[j].y;
      const intersect = (yi > y) !== (yj > y) && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }
    return inside;
  }
}

export class Spinner {
  /** @type {GameMode} */
  #game = null;
  /** @type {BackendDriver} */
  #backend = null;
  /** @type {AudioDriver} */
  #audio = null;
  /** @type {GameUI} */
  #ui = null;
  /** @type {HapticsDriver} */
  #haptice = null;
  /** @type {HTMLElement} */
  #element = document.getElementById('spinner');
  /** @type {HTMLElement} */
  #velocityElement = document.getElementById('spinner-velocity');
  /** @type {SpinnerTouch} */
  #ongoingTouch = null;
  /** @type {DOMHighResTimeStamp} */
  #lastTime = 0;
  #center = new SpinnerPoint(0, 0);
  #scale = this.#element.getBoundingClientRect().width / 100;
  #velocity = 0;
  #angle = 0;
  #touchStartAngle = 0;
  /** @type {SpinnerPoint[]} */
  #collision = [
    new SpinnerPoint(50, 0),
    new SpinnerPoint(42, 27),
    new SpinnerPoint(21, 45),
    new SpinnerPoint(-7, 49),
    new SpinnerPoint(-33, 38),
    new SpinnerPoint(-48, 14),
    new SpinnerPoint(-48, -14),
    new SpinnerPoint(-33, -38),
    new SpinnerPoint(-7, -49),
    new SpinnerPoint(21, -45),
    new SpinnerPoint(42, -27),
  ];
  #canvas = document.getElementById('spinner-canvas');

  constructor(game, backend, audio, ui, haptics) {
    this.#game = game;
    this.#backend = backend;
    this.#audio = audio;
    this.#ui = ui;
    this.#haptice = haptics;

    this.#element.addEventListener('touchstart', this.#touchStart.bind(this));
    this.#element.addEventListener('touchmove', this.#touchMove.bind(this));
    this.#element.addEventListener('touchend', this.#touchEnd.bind(this));
    this.#element.addEventListener('touchcancel', this.#touchCancel.bind(this));

    const r = this.#element.getBoundingClientRect();
    this.#center = new SpinnerPoint(r.left + r.width / 2, r.top + r.height / 2);
  }

  #getVelocityDecay() {
    return this.#game.getVelocityDecay();
  }

  #touchCount = 0;

  /** @param {?TouchEvent} event */
  async #touchStart(event) {
    event.preventDefault();

    // Prevent multiple touches.
    if (this.#ongoingTouch) {
      return;
    }

    this.#hideSpinVelocityEffect();

    const touches = event.changedTouches;
    if (touches.length === 0) {
      return;
    }

    const touch = new SpinnerTouch(touches[0]);
    const touchPoint = new SpinnerPoint(touch.x - this.#center.x, touch.y - this.#center.y);

    const transformedCollision = this.#collision.map(p => p.scale(this.#scale).rotate(this.#angle));
    if (!touchPoint.isInPolygon(transformedCollision)) {
      return;
    }

    this.#drawDebugSpinnerCollision();

    this.#touchCount = 0;

    this.#ongoingTouch = touch;
    this.#touchStartAngle = this.#angle;
    await this.#sendMessageStopSpin();
  }

  /** @param {?TouchEvent} event */
  async #touchMove(event) {
    if (!this.#ongoingTouch) {
      return;
    }

    this.#touchCount++;

    const touches = event.changedTouches;
    for (let i = 0; i < touches.length; i++) {
      if (this.#ongoingTouch.id === touches[i].identifier) {
        this.#ongoingTouch.update(touches[i]);
      }
    }

    // Rotated the spinner full circle, unwind the touch.
    const a = this.#getCumulativeOngoingTouchAngle();
    if (Math.abs(a) >= Math.PI * 2) {
      await this.#sendMessageManualSpin();
      this.#ongoingTouch.unwind();
      this.#playScoreEffects(this.#game.getMultiplier());
    }

    this.#setAngle(this.#touchStartAngle + a);

    const t = (new Date()).getTime();
    const dt = (t - this.#ongoingTouch.time);

    // this.#drawOngoingTouchTrail();
    if (dt > 0) {
      this.#setVelocity(a / dt);
    }
  }

  /** @param {?TouchEvent} event */
  async #touchEnd(event) {
    event.preventDefault();

    if (!this.#ongoingTouch) return;

    let found = false;
    const touches = event.changedTouches;
    for (let i = 0; i < touches.length; i++) {
      if (this.#ongoingTouch.id === touches[i].identifier) {
        found = true;
        break;
      }
    }
    if (!found) return;

    const a = this.#getCumulativeOngoingTouchAngle();
    if (Math.abs(a) >= Math.PI * 2) {
      this.#ongoingTouch.unwind();
    }

    this.#setAngle(this.#touchStartAngle + a);

    const velocity = this.#ongoingTouch.getInitialVelocity();
    this.#setVelocity(velocity);

    setTimeout(() => {
      this.#showSpinVelocityEffect(velocity);
    }, 100);

    this.#ongoingTouch = null;

    window.requestAnimationFrame(this.#tick.bind(this));

    await this.#sendMessageStartSpin();
  }

  /** @param {?TouchEvent} event */
  #touchCancel(event) {
    event.preventDefault();

    this.#ongoingTouch = null;
  }

  async #tick(time) {
    if (this.#ongoingTouch) return;

    const dt = time - this.#lastTime;

    if (this.#velocity !== 0) {
      this.#setAngle(this.#angle + this.#velocity * dt);
      if (Math.abs(this.#angle) >= Math.PI * 2) {
        this.#playScoreEffects(this.#game.getMultiplier());
        this.#unwindAngle();
      }

      this.#setVelocity(this.#velocity * this.#getVelocityDecay());
      if (Math.abs(this.#velocity) <= SpinnerTouch.MIN_VELOCITY) {
        this.#setVelocity(0);
        await this.#sendMessageStopSpin();
        return;
      }
    }

    this.#lastTime = time;

    if (this.#velocity !== 0) {
      window.requestAnimationFrame(this.#tick.bind(this));
    }
  }

  #setAngle(a) {
    this.#angle = a;
    this.#updateTransform();
  }

  #setVelocity(v) {
    this.#velocity = v;

    const dv = Math.abs(v) * 1000;

    this.#velocityElement.textContent = (dv / (Math.PI * 2)).toFixed(1); // todo correctly calculate velocity

    this.#updateAudio();
  }

  #hideSpinVelocityEffect() {
    this.#ui.hideSpinSpeedEffect();
  }

  #showSpinVelocityEffect(v) {
    const dv = Math.abs(v) * 1000;

    if (dv >= 3000) {
      this.#velocityElement.className = 'godlike';
      this.#ui.showSpinSpeedEffect(dv, 7);
    } else if (dv >= 2000) {
      this.#velocityElement.className = 'legendary';
      this.#ui.showSpinSpeedEffect(dv, 6);
    } else if (dv >= 1000) {
      this.#velocityElement.className = 'epic';
      this.#ui.showSpinSpeedEffect(dv, 5);
    } else if (dv >= 500) {
      this.#velocityElement.className = 'amazing';
      this.#ui.showSpinSpeedEffect(dv, 4);
    } else if (dv >= 250) {
      this.#velocityElement.className = 'great';
      this.#ui.showSpinSpeedEffect(dv, 3);
    } else if (dv >= 100) {
      this.#velocityElement.className = 'high';
      this.#ui.showSpinSpeedEffect(dv, 2);
    } else if (dv >= 25) {
      this.#velocityElement.className = 'medium';
      this.#ui.showSpinSpeedEffect(dv, 1);
    } else if (dv > 0) {
      this.#velocityElement.className = 'low';
      this.#ui.showSpinSpeedEffect(dv, 0);
    }
  }

  async #sendMessageManualSpin() {
    try {
      let data = {
        t: ClientMessageTypes.ManualSpin,
        d: {
          p: this.#ongoingTouch.path
        }
      };
      return this.#backend.sendDataToWS(JSON.stringify(data))
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async #sendMessageStartSpin() {
    try {
      let data = {
        t: ClientMessageTypes.StartSpin,
        d: {
          v: this.#velocity,
          a: this.#angle,
          t: Date.now()
        }
      };
      return this.#backend.sendDataToWS(JSON.stringify(data))
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async #sendMessageStopSpin() {
    try {
      let data = {
        t: ClientMessageTypes.StopSpin,
        d: {
          a: this.#angle,
          t: Date.now()
        }
      };

      return this.#backend.sendDataToWS(JSON.stringify(data));
    } catch (error) {
      return Promise.reject(error);
    }
  }

  #updateTransform() {
    if (!this.#element) return;
    this.#element.style.transform = `rotate(${this.#angle}rad)`;
  }

  #updateAudio() {
    if (!this.#audio) {
      return;
    }

    if (this.#audio.isSpinningSoundPlaying()) {
      if (Math.abs(this.#velocity) <= this.#game.getMinVelocity()) {
        this.#audio.stopSpinningSound();
      } else {
        this.#audio.updateSpinningVolume(this.#velocity);
      }
    } else {
      if (Math.abs(this.#velocity) > this.#game.getMinVelocity()) {
        this.#audio.playSpinningSound();
        this.#audio.updateSpinningVolume(this.#velocity);
      } else {
        this.#audio.stopSpinningSound();
      }
    }
  }

  #playScoreEffects(score) {
    const energy = this.#game.getEnergy();
    if (energy > 0) {
      this.#audio.playScoreSound();
      this.#haptice.playScoreHaptic();
      this.#ui.spawnScoreParticle(score % energy);
    }
  }

  #getOngoingTouchAngle() {
    const r = this.#element.getBoundingClientRect();
    const cx = r.left + r.width / 2;
    const cy = r.top + r.height / 2;
    const dx = this.#ongoingTouch.x - cx;
    const dy = this.#ongoingTouch.y - cy;
    return Math.atan2(dy, dx);
  }

  #unwindAngle() {
    let a = this.#angle;
    if (a < 0) {
      a += Math.PI * 2;
    }
    a %= Math.PI * 2;
    this.#angle = a;
  }

  #getCumulativeOngoingTouchAngle() {
    const r = this.#element.getBoundingClientRect();
    const cx = r.left + r.width / 2;
    const cy = r.top + r.height / 2;
    let a = 0;
    const p = this.#ongoingTouch.path;

    for (let i = 1; i < p.length; i++) {
      const p0 = p[i - 1];
      const p1 = p[i];
      const a0 = Math.atan2(p0.y - cy, p0.x - cx);
      const a1 = Math.atan2(p1.y - cy, p1.x - cx);
      let da = a1 - a0;

      if (da > Math.PI) da -= 2 * Math.PI;
      if (da < -Math.PI) da += 2 * Math.PI;

      a += da;
    }

    return a;
  }

  #drawOngoingTouchTrail() {
    // not implemented correctly, need to take a copy of the path, draw each point as a circle/arc and track each point draw time to fade out
    // const canvas = this.#canvas;
    // const ctx = canvas.getContext('2d');
    // const path = this.#ongoingTouch.path;
    // if (path.length < 2) return;
    // ctx.clearRect(0, 0, canvas.width, canvas.height);
    // ctx.beginPath();
    // ctx.arc(path[0].x, path[0].y, 5, 0, 2 * Math.PI, true);
    // ctx.fillStyle = 'rgba(255,255,255,1.0)';
    // ctx.fill();
    // requestAnimationFrame(this.#drawOngoingTouchTrail.bind(this));
  }

  #drawDebugSpinnerCollision() {
    this.#drawDebugCollision(this.#collision, this.#element, this.#angle, this.#scale);
  }

  #drawDebugCollision(path, element, angle, scale) {
    const canvas = this.#canvas;
    const rect = element.getBoundingClientRect();

    canvas.width = rect.width;
    canvas.height = rect.height;
    const ctx = canvas.getContext('2d');

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const centerX = rect.width / 2;
    const centerY = rect.height / 2;
    const transformedProfile = path.map(point => {
      const scaledPoint = {
        x: point.x * scale,
        y: point.y * scale,
      };
      const rotatedPoint = {
        x: scaledPoint.x * Math.cos(angle) - scaledPoint.y * Math.sin(angle),
        y: scaledPoint.x * Math.sin(angle) + scaledPoint.y * Math.cos(angle),
      };
      return {
        x: rotatedPoint.x + centerX,
        y: rotatedPoint.y + centerY,
      };
    });

    ctx.beginPath();
    ctx.moveTo(transformedProfile[0].x, transformedProfile[0].y);
    for (let i = 1; i < transformedProfile.length; i++) {
      ctx.lineTo(transformedProfile[i].x, transformedProfile[i].y);
    }
    ctx.closePath();

    // outline
    ctx.strokeStyle = 'red';
    ctx.lineWidth = 2;
    ctx.stroke();

    // fill
    ctx.fillStyle = 'rgba(255, 0, 0, 0.3)';
    ctx.fill();
  }
}