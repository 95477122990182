/**
 * Represents a driver for managing device orientation events.
 */
export class DeviceOrientationDriver {
  // --- Properties ---
  #isEnabled = false;

  isTouching = false;
  lastOrientation = {alpha: 0, beta: 0, gamma: 0};
  velocity = {x: 0, y: 0};
  position = {x: window.innerWidth / 2, y: window.innerHeight / 2};
  lastUpdateTime = Date.now();
  isFixed = true;
  lastAcceleration = {x: 0, y: 0, z: 0};
  lastPosition = {x: window.innerWidth / 2, y: window.innerHeight / 2};
  rotationAngle = 0;
  shakeThreshold = 30;

  /** @type {?GameUI} */
  #gameUI = null;

  /** @type {?HTMLElement} */
  debugStringElement = null;

  setFixed(isFixed) {
    this.isFixed = isFixed;

    if (!isFixed) {
      // Reattach the spinner-center to the body.
      let spinnerCenter = document.getElementById('spinner-center');
      if (spinnerCenter) {
        document.body.appendChild(spinnerCenter);
        spinnerCenter.style.top = '0';
        spinnerCenter.style.left = '0';
        spinnerCenter.addEventListener("touchstart", this.spinnerCenterTouchStart.bind(this));
      }
    } else {
      // Reattach the spinner-center to the spinner-container.
      let spinnerContainer = document.getElementById('spinner-container');
      if (spinnerContainer) {
        let spinnerCenter = document.getElementById('spinner-center');
        if (spinnerCenter) {
          spinnerContainer.appendChild(spinnerCenter);
          // Reset positioning.
          spinnerCenter.style.top = '50%';
          spinnerCenter.style.left = '50%';
        }
      }
    }
  }

  spinnerCenterTouchStart(event) {
    if (!event) {
      return;
    }

    event.preventDefault();

    document.ontouchmove = this.spinnerCenterTouchMove.bind(this);
    document.ontouchend = this.spinnerCenterTouchEnd.bind(this);

    this.isTouching = true;
  }

  spinnerCenterTouchMove(event) {
    if (!event || !this.isTouching) {
      return;
    }

    console.log(event.touches[0]);

    event.preventDefault();

    let spinnerCenter = document.getElementById('spinner-center');
    if (spinnerCenter) {
      spinnerCenter.style.top = event.touches[0].clientY + 'px';
      spinnerCenter.style.left = event.touches[0].clientX + 'px';

      let spinner = document.getElementById('spinner');
      if (spinner) {
        let spinnerRect = spinner.getBoundingClientRect();
        let spinnerWidth = spinnerRect.width / 2.1;
        let spinnerHeight = spinnerRect.height / 2.1;
        if (event.touches[0].clientX >= spinnerRect.left + spinnerWidth && event.touches[0].clientX <= spinnerRect.right - spinnerWidth &&
          event.touches[0].clientY >= spinnerRect.top + spinnerHeight && event.touches[0].clientY <= spinnerRect.bottom - spinnerHeight) {
          this.setFixed(true);
          this.isTouching = false;
          document.ontouchmove = null;
          document.ontouchend = null;
        }
      }
    }
  }

  spinnerCenterTouchEnd(event) {
    if (!event || !this.isTouching) {
      return;
    }

    event.preventDefault();

    this.isTouching = false;
    document.ontouchmove = null;
    document.ontouchend = null;
  }

  init() {
    this.debugStringElement = document.getElementById('debug');

    // this.debugStringElement.onclick = () => {
    //   this.setFixed(!this.isFixed);
    // }

    const isEnabledValue = window.localStorage.getItem('orientationDriver.isEnabled');
    if (isEnabledValue) {
      this.#isEnabled = JSON.parse(isEnabledValue);
    }

    // Check if the device supports device orientation events.
    if (window.DeviceOrientationEvent) {
      if (this.#isEnabled === true) {
        window.ondeviceorientation = this.handleOrientation.bind(this);
      } else {
        window.ondeviceorientation = null;
      }
    }

    this.setFixed(true);
    this.initOrientation();
  }

  setUI(gameUI) {
    this.#gameUI = gameUI;
  }

  initOrientation() {
    // Check if the device supports device orientation events.
    if (window.DeviceOrientationEvent) {
      window.addEventListener('deviceorientation', this.handleOrientation.bind(this));
      window.addEventListener('devicemotion', this.handleMotion.bind(this));
    }
  }

  requestPermission() {
    if (typeof DeviceMotionEvent.requestPermission === 'function') {
      DeviceMotionEvent.requestPermission()
        .then(permissionState => {
          if (permissionState === 'granted') {
            this.initOrientation();
          }
        })
        .catch((err) => {
          console.error(`Error requesting device orientation permission: ${err}`);
        });
    } else {
      this.initOrientation();
    }
  }

  handleMotion(event) {
    if (!event.accelerationIncludingGravity) {
      return;
    }

    return; // Block the shake detection.

    const {x, y, z} = event.accelerationIncludingGravity;
    const deltaX = x - this.lastAcceleration.x;
    const deltaY = y - this.lastAcceleration.y;
    const deltaZ = z - this.lastAcceleration.z;
    const shakeMagnitude = Math.sqrt(deltaX * deltaX + deltaY * deltaY + deltaZ * deltaZ);

    if (shakeMagnitude > this.shakeThreshold && this.isFixed) {
      this.setFixed(false);

      if (this.#gameUI) {
        this.#gameUI.addPopupMessage("You've broken the spinner!", "warning", 1000);
      }
    }

    this.lastAcceleration = {x, y, z};
  }

  handleOrientation(event) {
    if (!window.DeviceOrientationEvent) {
      return;
    }

    if (this.isFixed) {
      return;
    }

    const maxTilt = 90;
    const tiltX = Math.max(-maxTilt, Math.min(maxTilt, event.gamma));
    const tiltY = Math.max(-maxTilt, Math.min(maxTilt, event.beta));
    const friction = 0.95;
    const sensitivity = 0.1;
    const bounceDamping = -1.0;
    const imagePadding = 20;

    let debugElement = document.getElementById('debug');
    if (debugElement) {
      debugElement.textContent = `acc: ${this.lastAcceleration.x.toFixed(2)}, ${this.lastAcceleration.y.toFixed(2)}, ${this.lastAcceleration.z.toFixed(2)}, px: ${this.position.x.toFixed(2)}, py: ${this.position.y.toFixed(2)}`;
    }

    const positionX = (50 + (tiltX / maxTilt) * 50) + '%';
    const positionY = (50 + (tiltY / maxTilt) * 50) + '%';

    document.body.style.backgroundPositionX = positionX;
    document.body.style.backgroundPositionY = positionY;

    let spinnerCenter = document.getElementById('spinner-center');
    if (spinnerCenter) {
      const diameter = spinnerCenter.offsetWidth;
      const radius = diameter / 2;
      const visibleRadius = radius - imagePadding;

      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      const now = Date.now();
      const dt = (now - this.lastUpdateTime) / 1000;
      this.lastUpdateTime = now;

      this.velocity.x += (tiltX / maxTilt) * sensitivity;
      this.velocity.y += (tiltY / maxTilt) * sensitivity;

      this.velocity.x *= friction;
      this.velocity.y *= friction;

      this.position.x += this.velocity.x * dt * screenWidth;
      this.position.y += this.velocity.y * dt * screenHeight;

      if (this.position.x <= visibleRadius) {
        this.velocity.x *= bounceDamping;
        this.position.x = visibleRadius;
      } else if (this.position.x >= screenWidth - visibleRadius) {
        this.velocity.x *= bounceDamping;
        this.position.x = screenWidth - visibleRadius;
      }

      if (this.position.y <= visibleRadius) {
        this.velocity.y *= bounceDamping;
        this.position.y = visibleRadius;
      } else if (this.position.y >= screenHeight - visibleRadius) {
        this.velocity.y *= bounceDamping;
        this.position.y = screenHeight - visibleRadius;
      }

      if (this.lastPosition == null) {
        this.lastPosition = {x: this.position.x, y: this.position.y};
      }

      if (this.rotationAngle == null) {
        this.rotationAngle = 0;
      }

      const deltaX = this.position.x - this.lastPosition.x;
      const deltaY = this.position.y - this.lastPosition.y;
      const deltaDistance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
      const deltaRotation = (deltaDistance / radius) * (180 / Math.PI);

      const movementAngle = Math.atan2(deltaY, deltaX);
      const rotationDirection = Math.cos(movementAngle) >= 0 ? -1 : 1;

      this.rotationAngle += deltaRotation * rotationDirection;

      spinnerCenter.style.transform = `translate(${this.position.x - radius}px, ${this.position.y - radius}px) rotate(${this.rotationAngle}deg)`;

      this.lastPosition = {x: this.position.x, y: this.position.y};
    }

    this.lastOrientation = {alpha: event.alpha, beta: event.beta, gamma: event.gamma};
  }

  toggleParallax() {
    this.#isEnabled = !this.#isEnabled;

    if (this.#isEnabled) {
      window.ondeviceorientation = null
      window.ondeviceorientation = this.handleOrientation.bind(this);
    } else {
      window.ondeviceorientation = null
    }

    // Update persistent setting.
    window.localStorage.setItem('orientationDriver.isEnabled', JSON.stringify(this.#isEnabled));
  }

  isEnabled() {
    return this.#isEnabled;
  }
}
