export class FxDriver {

  #particleNumMin = 17;
  #particleNumMax = 77;
  #particleNum = 17;
  #maxParticleSize = 7;
  #maxParticleDelayMs = 777;
  #maxScoreParticles = 100;

  init() {
    this.#particleNum = Math.floor(Math.random() * (this.#particleNumMax - this.#particleNumMin + 1)) + this.#particleNumMin;
    this.createStars("fx-stars", this.#particleNum, this.#maxParticleSize, this.#maxParticleDelayMs);
    this.createScorePool("fx-score-pool", 100);
    this.createBoostParticles("fx-boost-particle-container", 100);
  }

  createParticle(id) {
    const delay = Number((Math.random() * this.#maxParticleDelayMs).toFixed());
    const size = 1 + Number((Math.random() * (this.#maxParticleSize - 1)).toFixed());

    const particleDiv = document.createElement('div');
    particleDiv.id = "fx-star-" + id
    particleDiv.className = "fx-star";
    particleDiv.style.top = 5 + (Math.random() * 85) + "%";
    particleDiv.style.left = 5 + (Math.random() * 90) + "%";
    particleDiv.style.width = size + "px";
    particleDiv.style.height = size + "px";

    let color = Number((size * 192 / this.#maxParticleSize).toFixed()).toString(16);
    color = "#" + color + "" + color + "" + color;
    particleDiv.style.background = color;

    particleDiv.style.background = "url('/fx-star-particle.png') no-repeat center center";
    particleDiv.style.backgroundSize = "contain";

    particleDiv.style.animationDelay = delay + "ms";
    particleDiv.style.WebkitAnimationDelay = delay + "ms";

    return particleDiv;
  }

  createStars(containerId) {
    const container = document.getElementById(containerId);

    for (let i = 0; i < this.#particleNumMin; i++) {
      container.appendChild(this.createParticle(i));
    }
  }

  // Create a pool of score elements with +1 and float up CSS animation.
  createScorePool(containerId, num) {
    const container = document.getElementById(containerId);

    for (let i = 0; i < num; i++) {
      const scoreEl = document.createElement('div');
      scoreEl.id = "fx-score-" + i;
      scoreEl.className = "fx-score";
      scoreEl.style.display = "none";
      scoreEl.innerHTML = "+1";
      container.appendChild(scoreEl);
    }
  }

  createBoostParticles(containerId, num) {
    const container = document.getElementById(containerId);

    for (let i = 0; i < num; i++) {
      const particleDiv = document.createElement('div');
      particleDiv.id = "fx-boost-" + i;
      particleDiv.className = "fx-boost-particle";
      particleDiv.style.left = (Math.random() * 100) + "%";
      particleDiv.style.animationDelay = (Math.random() * 1000) + "ms";
      container.appendChild(particleDiv);
    }
  }

  setBoostActive(active) {
    const boostParticles = document.getElementById("fx-stars");

    if (active) {
      boostParticles.classList.add("boosted");
    } else {
      boostParticles.classList.remove("boosted");
    }
  }

  showScore(element, score, boosted) {
    const scoreEl = document.getElementById("fx-score-" + Math.floor(Math.random() * this.#maxScoreParticles));
    const rect = element.getBoundingClientRect();
    const radius = Math.random() * Math.min(rect.width, rect.height) / 2;
    const angle = Math.random() * Math.PI * 2;
    const x = rect.left + rect.width / 2 + radius * Math.cos(angle);
    const y = rect.top + rect.height / 2 + radius * Math.sin(angle);

    scoreEl.style.left = x + "px";
    scoreEl.style.top = y + "px";
    scoreEl.style.display = "flex";
    scoreEl.innerHTML = "+" + score;

    if (boosted) {
      scoreEl.classList.add("boosted");
    }

    scoreEl.addEventListener("animationend", function () {
      scoreEl.style.display = "none";
      scoreEl.classList.remove("boosted");
    });
  }

  spinSpeedEffectTimeout = null;

  hideSpinSpeedEffect() {
    const spinSpeedEl = document.getElementById("fx-spin-speed");
    spinSpeedEl.className = "";

    clearTimeout(this.spinSpeedEffectTimeout);
    this.spinSpeedEffectTimeout = null;
  }

  showSpinSpeedEffect(v, tier) {
    const spinSpeedEl = document.getElementById("fx-spin-speed");
    if (this.spinSpeedEffectTimeout != null) {
      return;
    }

    const dv = v.toFixed(2);

    switch (tier) {
      case 0:
        spinSpeedEl.innerHTML = `${dv} rps<br>Boring...`;
        spinSpeedEl.className = "low";
        break;
      case 1:
        spinSpeedEl.innerHTML = `${dv} rps<br>OK!`;
        spinSpeedEl.className = "normal";
        break;
      case 2:
        spinSpeedEl.innerHTML = `${dv} rps<br>Good!`;
        spinSpeedEl.className = "high";
        break;
      case 3:
        spinSpeedEl.innerHTML = `${dv} rps<br>Great!`;
        spinSpeedEl.className = "great";
        break;
      case 4:
        spinSpeedEl.innerHTML = `${dv} rps<br>Amazing!`;
        spinSpeedEl.className = "amazing";
        break;
      case 5:
        spinSpeedEl.innerHTML = `${dv} rps<br>Epic!`;
        spinSpeedEl.className = "epic";
        break;
      case 6:
        spinSpeedEl.innerHTML = `${dv} rps<br>Legendary!`;
        spinSpeedEl.className = "legendary";
        break;
      case 7:
        spinSpeedEl.innerHTML = `${dv} rps<br>Demonic!`;
        spinSpeedEl.className = "daemon";
        break;
      case 8:
        spinSpeedEl.innerHTML = `${dv} rps<br>Godlike!`;
        spinSpeedEl.className = "godlike";
        break;
      case 9:
        spinSpeedEl.innerHTML = `${dv} rps<br>Impossible!`;
        spinSpeedEl.className = "impossible";
        break;
    }
    spinSpeedEl.class = tier;

    this.spinSpeedEffectTimeout = setTimeout(() => {
      clearTimeout(this.spinSpeedEffectTimeout);
      this.spinSpeedEffectTimeout = null;
    }, 3000);
  }
}
